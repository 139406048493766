import { formatCNPJ } from "shared/parsers/cnpj";
import { formatPhone } from "shared/parsers/phone";

export interface Column {
  id:
    | "id"
    | "corporate_name"
    | "fantasy_name"
    | "cellphone"
    | "document"
    | "created_at"
    | "options";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: string) => string;
}

const columns: Column[] = [
  {
    id: "id",
    label: "ID",
    minWidth: 80,
  },
  {
    id: "corporate_name",
    label: "Razão Social",
    minWidth: 170,
  },
  {
    id: "fantasy_name",
    label: "Fantasia",
    minWidth: 170,
    align: "right",
  },
  {
    id: "document",
    label: "CNPJ",
    minWidth: 170,
    align: "right",
    format: formatCNPJ,
  },
  {
    id: "cellphone",
    label: "Telefone",
    minWidth: 170,
    align: "right",
    format: formatPhone,
  },
  {
    id: "options",
    label: "Ações",
    minWidth: 170,
    align: "right",
  },
];

export { columns };
