import axiosInstance from "shared/services";
import { ICompanies } from "modules/companies/types";

const uri = "companies";

export const all = async (
  filterCompanies: boolean = false
): Promise<ICompanies> =>
  axiosInstance
    .get(`${uri}/all?filterCompanies=${filterCompanies ? 1 : 0}`)
    .then();
