import React from "react";
import { TextField, Box } from "@mui/material";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { useForm } from "react-hook-form";

import useCrud from "shared/hooks/use-crud";
import { setURI, store } from "shared/services/crud";
import { IStorage } from "modules/storages/types";
import { Form } from "shared/components/Form";

import JSONInput from "react-json-editor-ajrm";
import { locale, darktheme } from "./jsonEditorConfig";

const messages = defineMessages({
  titleCreate: { id: "storages.create" },
  titleEdit: { id: "storages.edit" },
  create: { id: "create" },
  save: { id: "save" },
  required: { id: "required" },
  success: { id: "crud.register.success" },
  error: { id: "crud.register.error" },
});

interface IForm {
  id?: number;
  name: string;
  uid: string;
  config?: any;
}

const initialValues: IStorage = {
  name: " ",
  uid: " ",
  config: {},
};

const FormStorages: React.FC = () => {
  const uri = "/storages";
  setURI(uri);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getById, editData } = useCrud<IStorage>({
    uri,
    initialValues,
  });
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IForm>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  React.useEffect(() => {
    getById(Number(id));
  }, [getById, id]);

  React.useEffect(() => {
    const formData = {
      name: editData.name,
      uid: editData.uid,
    };
    reset(formData);
  }, [editData, reset]);

  const onSubmit = async (values: IForm) => {
    const data = { ...values };
    if (id) data.id = parseInt(id);
    return store<IStorage>(data)
      .then(() => {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        navigate(ROUTES.CONFIG.STORAGES);
      })
      .catch(() =>
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        })
      );
  };

  return (
    <Form
      title={formatMessage(id ? messages.titleEdit : messages.titleCreate)}
      editMode={!!id}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      cancelAction={ROUTES.CONFIG.TAGS}
    >
      <div>
        <TextField
          label="Nome"
          fullWidth
          {...register("name", { required: true })}
          error={Boolean(errors.name)}
          helperText={
            errors.name?.type === "required" && formatMessage(messages.required)
          }
        />
      </div>
      <div>
        <TextField
          label="UID"
          fullWidth
          {...register("uid", { required: true })}
          error={Boolean(errors.uid)}
          helperText={
            errors.uid?.type === "required" && formatMessage(messages.required)
          }
        />
      </div>
      <div>
        <Box sx={{ display: "flex", width: "100%", mb: "10px" }}>
          Configuração
        </Box>
        <JSONInput
          id="storageConfig"
          placeholder={editData.config ? editData.config : {}}
          colors={darktheme}
          locale={locale}
          width="100%"
          height="550px"
          // @ts-ignore
          onChange={(e) => setValue("config", JSON.stringify(e.jsObject))}
        />
      </div>
    </Form>
  );
};

export { FormStorages };
