import { formatDate } from "shared/parsers/date";

export interface Column {
  id:
    | "id"
    | "user_group_id"
    | "company_ids"
    | "name"
    | "email"
    | "cellphone"
    | "created_at"
    | "options"
    | "companies";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string;
}

const columns: Column[] = [
  {
    id: "id",
    label: "ID",
    minWidth: 80,
  },
  {
    id: "companies",
    label: "Empresas",
    minWidth: 170,
    format: (value: any) => {
      const names: string[] = [];
      value.forEach((company) => names.push(company.fantasy_name));
      return names.length ? names.join(", ") : "Não definido";
    },
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
  },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "cellphone",
    label: "Celular",
    minWidth: 170,
  },
  {
    id: "created_at",
    label: "Criado em",
    minWidth: 170,
    align: "right",
    format: formatDate,
  },
  {
    id: "options",
    label: "Ações",
    minWidth: 170,
    align: "right",
  },
];

export { columns };
