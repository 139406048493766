import React from "react";

import { FormFiles } from "modules/files/components/Form";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <FormFiles />;
};

export default withAuthenticationRequired(withSideBar(Page));
