import React from "react";

import { FormCameras } from "modules/cameras/components/Form";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <FormCameras />;
};

export default withAuthenticationRequired(withSideBar(Page));
