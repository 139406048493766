import { Downloads } from "modules/files/components/Downloads";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <Downloads />;
};

export default withAuthenticationRequired(withSideBar(Page));
