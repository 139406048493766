import React from "react";
import { useLocation } from "react-router-dom";

import { IFile, IFileFilters } from "modules/files/types";
import { all } from "modules/files/services";

export interface IFilesMeta {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  first_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  previous_page_url: string;
  totalDashboard: {
    totalSearch: number;
    totalSize: number;
    totalDay: number;
    totalWeek: number;
    totalMonth: number;
    totalFiles: number;
  };
}

const useFiles = (filters: IFileFilters) => {
  const location = useLocation();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [meta, setMeta] = React.useState<IFilesMeta>({} as IFilesMeta);
  const [perPage, setPerPage] = React.useState<string>("20");

  const handleChangePage = (event: unknown, newPage: number) => {
    getAll(filters, newPage + 1, perPage);
  };

  const getAll = React.useCallback(
    async (filters: IFileFilters, page: number = 1, perPage = 20) => {
      const onlyVideos: boolean = location.pathname !== "/files";
      if (page < 1) page = 1;
      setLoading(true);
      const { data } = await all(filters, page, perPage, onlyVideos);
      setFiles(data.data);
      setMeta(data.meta);
      setLoading(false);
    },
    [location.pathname]
  );

  React.useEffect(() => {
    getAll(filters, 1, perPage);
  }, [getAll, filters, perPage]);

  return {
    files,
    loading,
    setLoading,
    handleChangePage,
    meta,
    perPage,
    setPerPage,
  };
};

export { useFiles };
