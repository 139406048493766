import React from "react";

import { IFile, IFileFilters } from "modules/files/types";
import { ICompany } from "modules/companies/types";
import { ICamera } from "modules/cameras/types";
import { ITag } from "modules/tags/types";

import { useFiles, IFilesMeta } from "shared/hooks/useFiles";
import { useCompanies } from "shared/hooks/useCompanies";
import { useCameras } from "shared/hooks/useCameras";
import { useTags } from "shared/hooks/useTags";

import { formatDate } from "shared/parsers/date";

export interface IFileFiltersContext {
  companies: ICompany[];
  cameras: ICamera[];
  tags: ITag[];
  files: IFile[];
  filters: IFileFilters;
  setFilters: (values: IFileFilters) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
  meta: IFilesMeta;
  handleChangePage: (event: unknown, value: number) => void;
  perPage: string;
  setPerPage: (value: string) => void;
}

const FileFiltersContext = React.createContext({} as IFileFiltersContext);

const FileFiltersProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = React.useState<IFileFilters>({
    startDate: formatDate(new Date(), "yyyy-MM-dd"),
    startTime: "00:00:00",
    endDate: formatDate(new Date(), "yyyy-MM-dd"),
    endTime: "23:59:59",
  } as IFileFilters);
  const {
    files,
    perPage,
    setPerPage,
    loading,
    setLoading,
    handleChangePage,
    meta,
  } = useFiles(filters);
  const { companies } = useCompanies(true);
  const { cameras } = useCameras();
  const { tags } = useTags();

  const value = React.useMemo(
    () => ({
      companies,
      cameras,
      tags,
      files,
      filters,
      setFilters,
      loading,
      setLoading,
      handleChangePage,
      meta,
      perPage,
      setPerPage,
    }),
    [
      companies,
      cameras,
      tags,
      files,
      filters,
      setFilters,
      loading,
      setLoading,
      handleChangePage,
      meta,
      perPage,
      setPerPage,
    ]
  );

  return (
    <FileFiltersContext.Provider value={value}>
      {children}
    </FileFiltersContext.Provider>
  );
};

const useFileFilters = (): IFileFiltersContext =>
  React.useContext<IFileFiltersContext>(FileFiltersContext);

export { FileFiltersContext, FileFiltersProvider, useFileFilters };
