import React from "react";

import { IntlProvider } from "react-intl";
import ptMessages from "locales/pt.json";

type Languages = "pt";

export interface IContext {
  locale: Languages;
  switchLanguage: (lang: Languages) => void;
}

const Context = React.createContext({} as IContext);

const menuMessages = {
  pt: { ...ptMessages },
};

const Provider: React.FC = ({ children }) => {
  const [locale, setLocale] = React.useState<Languages>("pt");
  const [messages, setMessages] = React.useState(menuMessages.pt);

  const switchLanguage = React.useCallback((lang: Languages) => {
    setLocale(lang);
    setMessages(menuMessages[lang]);
  }, []);

  const value = React.useMemo(
    () => ({
      locale,
      switchLanguage,
    }),
    [locale, switchLanguage]
  );

  return (
    <Context.Provider value={value}>
      <IntlProvider
        key={locale}
        locale={locale}
        messages={messages}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export const useIntlConsumer = (): IContext =>
  React.useContext<IContext>(Context);

export const { Consumer } = Context;

export default Provider;
