import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";
import * as Styled from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #CCC",
  boxShadow: 24,

  p: 4,
};

interface IProps {
  videoName: string;
  videoURL: any;
  open: boolean;
  onClose: () => void;
}

const Player: React.FC<IProps> = ({ videoName, videoURL, open, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  // const handleIFrame = (frame: any) => {
  //   return <div dangerouslySetInnerHTML={{ __html: frame }} />;
  // };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
          {videoName}
        </Typography>
        <hr />
        <Styled.ContainerVideo>
          {/* {videoURL && <>{handleIFrame(videoURL)}</>} */}
          <video width="640" height="480" controls>
            <source src={videoURL} type="video/mp4" />
          </video>
        </Styled.ContainerVideo>
        <Styled.ContainerButton>
          <Button type="button" onClick={handleClose}>
            Fechar
          </Button>
        </Styled.ContainerButton>
      </Box>
    </Modal>
  );
};

export { Player };
