import React from "react";

import {
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { useIntl, defineMessages } from "react-intl";

import MoreIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";

import { useSnackbarConsumer } from "shared/providers/Snackbar";

import { ConfirmDialog } from "./ConfirmDialog";

const messages = defineMessages({
  edit: { id: "edit" },
  delete: { id: "delete" },
  deleteConfirm: { id: "crud.delete.confirm" },
  deleteSuccess: { id: "crud.delete.success" },
  deleteError: { id: "crud.delete.error" },
});

interface IOptions {
  id: number;
  column: any;
  uri: string;
  onRemove: (param: number) => Promise<unknown>;
}

export const Options: React.FC<IOptions> = ({ id, column, uri, onRemove }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => navigate(`${uri}/edit/${id}`);

  const handleRemove = async () => {
    await onRemove(id)
      .then(() =>
        openSnackbar({
          message: formatMessage(messages.deleteSuccess),
          severity: "success",
        })
      )
      .catch(() =>
        openSnackbar({
          message: formatMessage(messages.deleteError),
          severity: "error",
        })
      );
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <>
      <TableCell key={column.id} align={column.align}>
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreIcon />
        </IconButton>
      </TableCell>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          padding: 2,
        }}
        PaperProps={{
          sx: {
            borderRadius: 2,
          },
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          {formatMessage(messages.edit)}
        </MenuItem>
        <MenuItem onClick={() => setOpenConfirm(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" color="error" />
          </ListItemIcon>
          <Typography color="error">
            {formatMessage(messages.delete)}
          </Typography>
        </MenuItem>
      </Menu>
      <ConfirmDialog
        title="Atenção! Ação sem retorno."
        description="Deseja realmente excluir o registro?"
        open={openConfirm}
        handleCancel={() => setOpenConfirm(false)}
        handleConfirm={handleRemove}
      />
    </>
  );
};

export interface ITCell {
  column: any;
  row: any;
  children: any;
}

const TCell: React.FC<ITCell> = ({ column, row, children }) => {
  if (column.id !== "options") {
    const value = row[column.id];

    if (!value) {
      return (
        <TableCell key={column.id} align={column.align}>
          -
        </TableCell>
      );
    }

    return (
      <TableCell key={column.id} align={column.align}>
        {column.format ? column.format(value) : value}
      </TableCell>
    );
  }

  return children;
};

export { TCell };
