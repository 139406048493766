/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Button } from "@mui/material";
import { useIntl, defineMessages } from "react-intl";
import { FormHead } from "./Header";

const messages = defineMessages({
  create: { id: "create" },
  save: { id: "save" },
  cancel: { id: "cancel" },
});

interface IForm {
  children: any;
  title: string;
  editMode: boolean;
  cancelAction?: string;
  handleSubmit: any;
  onSubmit: (values: any) => void;
}

const Form: React.FC<IForm> = ({
  children,
  editMode,
  cancelAction,
  title,
  handleSubmit,
  onSubmit,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  return (
    <Container maxWidth="lg">
      <FormHead title={title} />
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        boxShadow={1}
        style={{ display: "grid", gridAutoFlow: "row" }}
        sx={{ gap: 3, padding: 3, borderRadius: 4 }}
        noValidate
        autoComplete="off"
      >
        {children}
        <Box sx={{ mt: 3, textAlign: "right" }}>
          {cancelAction && (
            <Button type="button" onClick={() => navigate(cancelAction)}>
              {formatMessage(messages.cancel)}
            </Button>
          )}
          &nbsp;&nbsp;
          <Button variant="contained" type="submit">
            {formatMessage(editMode ? messages.save : messages.create)}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export { Form };
