import axiosInstance from "shared/services";
import {
  IFiles,
  IFileUploadResponse,
  IFileDownloadResponse,
  IFileFilters,
  IFileBatchDownloadResponse,
  IFileDownloadsResponse,
  IFileLogsResponse,
} from "modules/files/types";

const uri = "files";

export const all = async (
  filters: IFileFilters,
  page: number,
  perPage: string,
  onlyVideos: boolean = false
): Promise<IFiles> => {
  const {
    query,
    startDate,
    startTime,
    endDate,
    endTime,
    cameraId,
    tags,
    companyId,
  } = filters;
  const q: string[] = [
    `?page=${page}&perPage=${perPage}&query=${
      query && query.length >= 3 ? query : ""
    }&onlyVideos=${Number(onlyVideos)}&noRecordDate=${filters.noRecordDate}`,
  ];

  if (startDate && startTime && endDate && endTime)
    q.push(`&from=${startDate} ${startTime}&to=${endDate} ${endTime}`);
  if (companyId) q.push(`&companyId=${companyId}`);
  if (cameraId) q.push(`&cameraId=${cameraId}`);
  if (tags) q.push(`&tags=${tags}`);

  return await axiosInstance.get(`${uri}${q.join("")}`);
};

export const store = async (data: any): Promise<IFileUploadResponse> => {
  const form = new FormData();
  form.append("name", data.name);
  form.append("camera_id", data.camera_id);
  form.append("file_type_id", data.file_type_id);
  form.append("storages", data.storages);
  form.append("file", data.file);

  return await axiosInstance.post(`${uri}`, form);
};

export const downloadFile = async (
  id: number,
  forceDav: boolean = false
): Promise<IFileDownloadResponse> => {
  return await axiosInstance.get(
    `${uri}/download/${id}${forceDav ? "?dav=true" : ""}`
  );
};

export const watchFile = async (id: number): Promise<IFileDownloadResponse> => {
  return await axiosInstance.get(`${uri}/watch/${id}`);
};

export const storeBatchDownload = async (
  ids: number[]
): Promise<IFileBatchDownloadResponse> => {
  return await axiosInstance.post(`video-queue`, { batch: ids });
};

export const downloadsFiles = async (): Promise<IFileDownloadsResponse> => {
  return await axiosInstance.get("video-queue");
};

export const checkTranscode = async (vimeoId: string): Promise<any> => {
  return await axiosInstance.patch(`video-queue/check-transcode`, { vimeoId });
};

export const cancelQueue = async (id: number): Promise<any> => {
  return await axiosInstance.patch(`video-queue/cancel/${id}`);
};

export const logs = async (): Promise<IFileLogsResponse> => {
  return await axiosInstance.get(`${uri}/logs`);
};
