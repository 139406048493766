import React from "react";
import { list, remove, first, setURI } from "shared/services/crud";
import { Meta } from "shared/types";

type State = "loading" | "error" | "idle" | "loaded" | "deleting";

type Response<T> = {
  data: T[];
  meta: Meta;
  editData: T;
  state: State;
  isEmpty: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  isDeleting: boolean;
  get: (page?: number) => void;
  getById: (id: number) => void;
  deleteById: (id: number) => Promise<unknown>;
  setEditData: (value: T) => void;
};

interface IUseCrud {
  uri: string;
  initialValues?: any;
  filters?: any;
}

function useCrud<T>({ uri, initialValues, filters }: IUseCrud): Response<T> {
  interface IData {
    data: T[];
    meta: Meta;
  }

  setURI(uri);
  const [data, setData] = React.useState<IData>({} as IData);
  const [editData, setEditData] = React.useState<T>(initialValues || ({} as T));
  const [state, setState] = React.useState<State>("idle");

  const isEmpty = Boolean(!data?.data?.length);

  const get = React.useCallback(
    async (page?: number) => {
      try {
        console.log("xxxxx", filters.query);
        setState("loading");
        const { data: dataResult } = await list(page, filters);
        setData(dataResult);
      } catch {
        setState("error");
      } finally {
        setState("loaded");
      }
    },
    [filters]
  );

  const getById = React.useCallback(async (id: number) => {
    try {
      setState("loading");

      const response = await first(id);

      setEditData(response);
    } catch {
      setState("error");
    } finally {
      setState("loaded");
    }
  }, []);

  const deleteById = React.useCallback(
    async (id: number): Promise<unknown> =>
      remove(id).then(() =>
        setData((cc) => ({
          ...cc,
          data: cc.data.filter((row: any) => row.id !== id),
        }))
      ),
    []
  );

  return {
    data: data.data,
    meta: data.meta,
    editData,
    state,
    isEmpty,
    get,
    getById,
    deleteById,
    setEditData,
    isLoading: state === "loading",
    isLoaded: state === "loaded",
    isDeleting: state === "deleting",
  };
}

export default useCrud;
