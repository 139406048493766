import React from "react";

import { Box, Button, TextField, Alert } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";

import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { useAuthenticationConsumer } from "shared/providers/Authentication";
import { FormBox } from "../FormBox";

const messages = defineMessages({
  title: { id: "sign.in.to.inspecionar" },
  subtitle: { id: "enter.details" },
  enter: { id: "enter" },
  email: { id: "email" },
  password: { id: "password" },
  required: { id: "required" },
  invalidEmail: { id: "invalid.email" },
  forgotPassword: { id: "forgot.password" },
  loginFailed: { id: "sign.in.failed" },
  cellphone: { id: "sign.in.cellphone" },
  loginBlocked: { id: "sign.in.blocked" },
});

type FormValues = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const {
    signIn,
    isAuthenticated,
    error,
    captchaRef,
    showSMS,
    setSmsCode,
    handleSubmitSms,
    blocked,
  } = useAuthenticationConsumer();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({ mode: "onBlur" });

  const goHome = React.useCallback(() => navigate(ROUTES.HOME), [navigate]);

  React.useEffect(() => {
    if (isAuthenticated) goHome();
  }, [isAuthenticated, goHome]);

  const onSubmit = ({ email, password }: FormValues) => signIn(email, password);

  return (
    <FormBox
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.subtitle)}
    >
      {showSMS ? (
        <Box
          sx={{
            paddingTop: 4,
            width: "100%",
          }}
        >
          <TextField
            label={formatMessage(messages.cellphone)}
            onChange={(e) => setSmsCode(e.target.value)}
            fullWidth
            sx={{
              mb: 2,
            }}
          />
          <Button
            variant="contained"
            type="button"
            size="large"
            fullWidth
            onClick={handleSubmitSms}
          >
            {formatMessage(messages.enter)}
          </Button>
        </Box>
      ) : (
        <div className="childrenForm">
          {!blocked && error.message && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {formatMessage(messages.loginFailed)}
            </Alert>
          )}

          {blocked && (
            <Alert severity="warning" sx={{ marginTop: 2 }}>
              {formatMessage(messages.loginBlocked)}
            </Alert>
          )}

          <Box
            onSubmit={handleSubmit(onSubmit)}
            component="form"
            sx={{
              paddingTop: 4,
              width: "100%",
            }}
          >
            <TextField
              label={formatMessage(messages.email)}
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
              error={Boolean(errors.email)}
              helperText={
                (errors.email?.type === "required" &&
                  formatMessage(messages.required)) ||
                (errors.email?.type === "pattern" &&
                  formatMessage(messages.invalidEmail))
              }
              fullWidth
              sx={{
                mb: 2,
              }}
            />
            <div>
              <TextField
                {...register("password", { required: true })}
                label={formatMessage(messages.password)}
                type="password"
                error={Boolean(errors.password)}
                helperText={
                  errors.password?.type === "required" &&
                  formatMessage(messages.required)
                }
                fullWidth
              />
            </div>
            <Box
              sx={{
                mb: 2,
                mt: 2,
              }}
            >
              <Link to="/forgot-password">
                <Button variant="text">
                  {formatMessage(messages.forgotPassword)}
                </Button>
              </Link>
            </Box>

            <Button variant="contained" type="submit" size="large" fullWidth>
              {formatMessage(messages.enter)}
            </Button>
          </Box>
        </div>
      )}
      <div id="sign-in-button" ref={captchaRef}></div>
    </FormBox>
  );
};

export { Login };
