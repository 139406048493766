/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import SideBar from "shared/templates/Sidebar";

type SideBarProps = {
  title: string;
  profileOptions: [
    {
      title: string | number;
      onClick: (params: unknown) => unknown;
    }
  ];
};

function withSideBar<P>(
  Component: React.ComponentType<P>,
  sideBarProps?: SideBarProps
): React.FC<P> {
  return function WithSideBar(props: P): JSX.Element {
    return (
      <SideBar {...sideBarProps}>
        {/*// @ts-ignore */}
        <Component {...props} />
      </SideBar>
    );
  };
}

export default withSideBar;
