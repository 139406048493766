const ROUTES = {
  HOME: "/",
  FILES: "/files",
  LOGIN: "/login",
  DASH: "/dash",
  FORGOT: "/forgot-password",
  RESETPASSWORD: "/change-password/:token",
  DOWNLOADS: "/downloads",
  DOWNLOADS_LOGS: "/downloads-logs",
  ACCESS_LOGS: "/access-logs",
  ADMIN: {
    ADD_FILES: "/files/add/",
    COMPANIES: "/companies",
    COMPANIES_CREATE: "/companies/create",
    COMPANIES_EDIT: "/companies/edit/:id",
    USER_GROUPS: "/user-groups",
    USER_GROUPS_CREATE: "/user-groups/create",
    USER_GROUPS_EDIT: "/user-groups/edit/:id",
    USERS: "/users",
    USERS_CREATE: "/users/create",
    USERS_EDIT: "/users/edit/:id",
    SCAN_LOGS: "/scan-logs",
  },
  CONFIG: {
    ROOT: "/config",
    CAMERAS: "/cameras",
    CAMERAS_CREATE: "/cameras/create",
    CAMERAS_EDIT: "/cameras/edit/:id",
    TAGS: "/tags",
    TAGS_CREATE: "/tags/create",
    TAGS_EDIT: "/tags/edit/:id",
    STORAGES: "/storages",
    STORAGES_CREATE: "/storages/create",
    STORAGES_EDIT: "/storages/edit/:id",
  },
};

export { ROUTES };
