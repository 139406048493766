import React from "react";
import InputMask from "react-input-mask";

import {
  Grid,
  Typography,
  TextField,
  Box,
  Container,
  Button,
  Tabs,
  Tab,
  Autocomplete,
  Divider,
} from "@mui/material";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { useForm, Controller } from "react-hook-form";
import useCrud from "shared/hooks/use-crud";
import { store, setURI } from "shared/services/crud";
import { zipcode } from "shared/services/zipcode";
import * as validations from "shared/validations/form";
import { ICompany } from "modules/companies/types";
import { UserCompanyRow } from "../UserCompanyRow";
import { IUser } from "modules/users/types";
import {
  all,
  connectUser,
  disconnectUser,
} from "modules/users/services/users-service";
import { v4 as uuidv4 } from "uuid";

interface IUserSelected {
  label: string;
  value: number;
}

const messages = defineMessages({
  titleCreate: { id: "companies.create" },
  titleEdit: { id: "companies.edit" },
  create: { id: "create" },
  save: { id: "save" },
  required: { id: "required" },
  invalid: { id: "invalid" },
  next: { id: "next" },
  success: { id: "crud.register.success" },
  error: { id: "crud.register.error" },
  userGroupField: { id: "users.user.group" },
  specializationField: { id: "user.specialization" },
  passwordNotMatch: { id: "password-not-match" },
  birthday: { id: "birthday" },
  connectSuccess: { id: "company.connect.success" },
  disconnectSuccess: { id: "company.disconnect.success" },
  cancel: { id: "cancel" },
});

const Header: React.FC = ({ children }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 5, overflowX: "auto" }}
    >
      <Typography variant="h5" component="h4" fontWeight="bold">
        {children}
      </Typography>
    </Grid>
  );
};

const initialValues: ICompany = {
  corporate_name: " ",
  fantasy_name: " ",
  document: " ",
  cellphone: " ",
  address: " ",
  number: " ",
  complement: " ",
  neighborhood: " ",
  city: " ",
  state: " ",
  zipcode: " ",
  uri: " ",
};

const FormCompanies: React.FC = () => {
  const uri = "/companies";
  setURI(uri);
  const [tabs, setTabs] = React.useState<number[]>([0, 1]);
  const [tab, setTab] = React.useState<number>(tabs[0]);
  const [usersOptions, setUsersOptions] = React.useState<IUserSelected[]>([]);
  const [userSelected, setUserSelected] = React.useState<IUserSelected>({
    label: "",
    value: 0,
  } as IUserSelected);
  const isLastTab = tab === tabs[tabs.length - 1];
  const navigate = useNavigate();
  const { id } = useParams();
  const { editData, getById } = useCrud<ICompany>({
    uri,
    initialValues,
  });

  const schema = yup.object().shape({
    document: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, "Digite um CNPJ válido"),
    fantasy_name: yup
      .string()
      .min(3, "O campo deve conter no mínimo 3 caracteres")
      .required("Campo obrigatório"),
    corporate_name: yup
      .string()
      .min(3, "O campo deve conter no mínimo 3 caracteres")
      .required("Campo obrigatório"),
    uri: yup
      .string()
      .min(3, "O campo deve conter no mínimo 3 caracteres")
      .required("Campo obrigatório"),
    cellphone: yup.string().required("Campo obrigatório"),
  });

  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    control,
  } = useForm<ICompany>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleUsers = React.useCallback(async () => {
    if (!id) return false;
    const { status, data } = await all();
    if (status === 200) {
      const values: IUserSelected[] = [];
      data.forEach((user: IUser) => {
        if (user.id) {
          values.push({
            label: `${user.name} ${user.surname} | ${user.email}`,
            value: user.id,
          });
        }
      });
      setUsersOptions(values);
    }
  }, [id]);

  const handleConnect = async () => {
    if (!id) return false;
    const { status } = await connectUser({
      companyId: parseInt(id),
      userId: userSelected.value,
    });
    if (status === 200) {
      getById(parseInt(id));
      openSnackbar({
        message: formatMessage(messages.connectSuccess),
        severity: "success",
      });
    }
  };

  const handleDisconnect = async (userId: number) => {
    if (!id) return false;
    const { status } = await disconnectUser({
      companyId: parseInt(id),
      userId,
    });
    if (status === 200) {
      getById(parseInt(id));
      openSnackbar({
        message: formatMessage(messages.disconnectSuccess),
        severity: "success",
      });
    }
  };

  React.useEffect(() => {
    handleUsers();
  }, [handleUsers]);

  React.useEffect(() => {
    if (!id) return;
    getById(Number(id));
    setTabs([...tabs, 2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getById, id]);

  React.useEffect(() => {
    editData.document = editData.document.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );
    reset(editData);
  }, [editData, reset]);

  const onSubmit = async ({
    document,
    zipcode,
    cellphone,
    ...values
  }: ICompany) => {
    if (!isLastTab) return setTab(tabs[tab + 1]);
    const data = {
      ...values,
      uri: values.uri.trim(),
      document: document ? document.replace(/[^\w\s]/gi, "") : "",
      zipcode: zipcode?.replace("-", ""),
      cellphone: cellphone
        ? cellphone.replace(/[^\w\s]/gi, "").replace(/\s/g, "")
        : null,
    };

    const company: any = await store(data);

    if (company && company.id) {
      openSnackbar({
        message: formatMessage(messages.success),
        severity: "success",
      });
      navigate(ROUTES.ADMIN.COMPANIES);
    } else {
      openSnackbar({
        message: "Já existe uma empresa configurada com esses dados.",
        severity: "error",
      });
      return false;
    }
  };

  const handleZipcode = async (zcode: string) => {
    const { status, data: dataZip } = await zipcode(zcode);
    if (status === 200) {
      setValue("zipcode", dataZip.cep);
      setValue("address", dataZip.logradouro);
      setValue("number", "");
      setValue("complement", "");
      setValue("neighborhood", dataZip.bairro);
      setValue("state", dataZip.uf);
      setValue("city", dataZip.localidade);
    }
  };

  return (
    <Container maxWidth="lg">
      <Header>
        {id
          ? formatMessage(messages.titleEdit)
          : formatMessage(messages.titleCreate)}
      </Header>

      <Tabs
        value={tab}
        onChange={(_, newValue: number) => setTab(newValue)}
        aria-label="basic tabs example"
        sx={{
          mb: 5,
        }}
      >
        <Tab label="Corporativo" id="tab-0" />
        <Tab label="Endereço" id="tab-1" />
        {id && <Tab label="Usuários" id="tab-2" />}
      </Tabs>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        boxShadow={1}
        style={{ display: "grid", gridAutoFlow: "row" }}
        sx={{ padding: 3, borderRadius: 4 }}
        autoComplete="off"
        noValidate
      >
        {tab === 0 && (
          <Box
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            sx={{ gap: 3 }}
          >
            <div>
              <TextField
                label="Razão Social"
                fullWidth
                {...register("corporate_name", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.corporate_name)}
                helperText={
                  errors.corporate_name && errors.corporate_name.message
                }
              />
            </div>
            <div>
              <TextField
                label="Nome Fantasia"
                fullWidth
                {...register("fantasy_name", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.fantasy_name)}
                helperText={errors.fantasy_name && errors.fantasy_name.message}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="document"
                rules={{
                  required: true,
                  minLength: {
                    value: 11,
                    message: "Invalid Document",
                  },
                }}
                render={({
                  field: { ...field },
                  fieldState: { invalid, error },
                }) => (
                  <InputMask mask="99.999.999/9999-99" {...field}>
                    {/*
                    // @ts-ignore:next-line */}
                    {(inputProps) => (
                      <TextField
                        label="CNPJ"
                        fullWidth
                        error={invalid}
                        helperText={errors.document && errors.document.message}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="cellphone"
                render={({
                  field: { ...field },
                  fieldState: { invalid, error },
                }) => (
                  <InputMask mask="(99) 9 9999-9999" {...field}>
                    {/*
                    // @ts-ignore:next-line */}
                    {(inputProps) => (
                      <TextField
                        label="Telefone"
                        fullWidth
                        error={invalid}
                        helperText={
                          validations.isRequiredError(error?.type) &&
                          formatMessage(messages.required)
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <div>
              <TextField
                label="Pasta Dropbox"
                fullWidth
                {...register("uri", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.uri)}
                helperText={errors.uri && errors.uri.message}
              />
            </div>
          </Box>
        )}
        {tab === 1 && (
          <Box
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            sx={{ gap: 3 }}
          >
            <div>
              <Controller
                control={control}
                name="zipcode"
                rules={{
                  required: true,
                }}
                render={({
                  field: { ...field },
                  fieldState: { invalid, error },
                }) => (
                  <InputMask
                    mask="99999-999"
                    {...field}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleZipcode(e.target.value)
                    }
                  >
                    {/*
                    // @ts-ignore:next-line */}
                    {(inputProps) => (
                      <TextField
                        label="CEP"
                        fullWidth
                        error={invalid}
                        helperText={
                          validations.isRequiredError(error?.type) &&
                          formatMessage(messages.required)
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <div>
              <TextField
                label="Endereço"
                fullWidth
                {...register("address", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.address)}
                helperText={errors.address && errors.address.message}
              />
            </div>
            <div>
              <TextField
                label="Número"
                fullWidth
                {...register("number", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.number)}
                helperText={errors.number && errors.number.message}
              />
            </div>
            <div>
              <TextField
                label="Complemento"
                fullWidth
                {...register("complement", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.complement)}
                helperText={errors.complement && errors.complement.message}
              />
            </div>
            <div>
              <TextField
                label="Bairro"
                fullWidth
                {...register("neighborhood", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.neighborhood)}
                helperText={errors.neighborhood && errors.neighborhood.message}
              />
            </div>
            <div>
              <TextField
                label="Estado"
                fullWidth
                {...register("state", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.state)}
                helperText={errors.state && errors.state.message}
              />
            </div>
            <div>
              <TextField
                label="Cidade"
                fullWidth
                {...register("city", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.city)}
                helperText={errors.city && errors.city.message}
                autoComplete="off"
              />
            </div>
          </Box>
        )}
        {tab === 2 && (
          <Box
            style={{ display: "grid", gridTemplateColumns: "2fr" }}
            sx={{ gap: 3 }}
          >
            <div>
              <Autocomplete
                autoHighlight
                options={usersOptions}
                getOptionLabel={(option) => option.label}
                onChange={(_, selected) => {
                  setUserSelected(selected as IUserSelected);
                }}
                value={userSelected}
                renderInput={(params) => (
                  <TextField {...params} label="Associar usuário" fullWidth />
                )}
              />
            </div>
            <div>
              <Button
                variant="contained"
                type="button"
                style={{ alignItems: "flex-end" }}
                onClick={() => handleConnect()}
              >
                Associar
              </Button>
            </div>
            <Divider />
            <div>
              {editData &&
                editData.users &&
                //@ts-ignore:next-line
                editData.users.map((user: User) => (
                  <UserCompanyRow
                    user={user}
                    key={uuidv4()}
                    onDisconnect={(userId: number) => handleDisconnect(userId)}
                  />
                ))}
            </div>
          </Box>
        )}

        <Box sx={{ mt: 3, textAlign: "right" }}>
          <Button
            type="button"
            onClick={() => navigate(ROUTES.ADMIN.COMPANIES)}
          >
            {formatMessage(messages.cancel)}
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" type="submit">
            {!isLastTab
              ? formatMessage(messages.next)
              : formatMessage(id ? messages.save : messages.create)}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export { FormCompanies };
