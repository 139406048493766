import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import styled from "@emotion/styled";
import { alpha } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { getIcon } from "shared/constants/icons";
import admin from "shared/constants/menus/admin";
import manager from "shared/constants/menus/manager";
import user from "shared/constants/menus/user";
import { me } from "shared/services";

export const CustomListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "active",
})(({ theme, active }) => {
  return {
    ...(active && {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    }),
  };
});

const LIST_ICON_STYLES = { minWidth: "auto", mr: 2 };

const hasSubMenu = (submenu = []) => Boolean(submenu.length > 0);

const useNavitagion = () => {
  const navigate = useNavigate();

  return (page) => () => {
    if (page) navigate(page, { replace: true });
  };
};

const WithSubMenus = function ({ menu, children, showListItemsTitle }) {
  const location = useLocation();
  const isSelected = location?.pathname === menu.url;
  // const Icon = getIcon(menu.icon);

  return (
    <>
      <ListItem button sx={{ display: showListItemsTitle ? "block" : "none" }}>
        {/* <ListItemIcon sx={LIST_ICON_STYLES}>
          <Icon sx={{ color: isSelected ? 'primary.main' : 'default' }} />
        </ListItemIcon> */}
        <ListItemText
          primary={menu.name}
          sx={{ color: isSelected ? "primary.main" : "default" }}
          primaryTypographyProps={{
            fontWeight: 600,
            fontSize: 12,
            textTransform: "uppercase",
            mb: 0.5,
          }}
        />
      </ListItem>
      <Collapse in={true} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

const SidebarMenu = function ({ showListItemsTitle }) {
  const [meData, setMeData] = React.useState({});
  const [menus, setMenus] = React.useState([]);
  const handleNavigation = useNavitagion();
  const location = useLocation();

  const loadMe = React.useCallback(async () => {
    const { status, data } = await me();
    if (status === 200) setMeData(data);
  }, []);

  React.useEffect(() => {
    loadMe();
  }, [loadMe]);

  React.useEffect(() => {
    if (meData && meData && meData.userGroup && meData.userGroup.uid) {
      if (meData.userGroup.uid === "ADMIN") setMenus(admin);
      if (meData.userGroup.uid === "MANAGER") setMenus(manager);
      if (meData.userGroup.uid === "USER") setMenus(user);
    }
  }, [meData]);

  return menus.map((menu, index) => {
    const MenuIcon = getIcon(menu.icon);
    return (
      <List
        key={menu.name}
        sx={{
          pl: 1,
          pr: 1,
          pt: 2,
          pb: 0,
        }}
      >
        {!hasSubMenu(menu.submenu) ? (
          <CustomListItem
            active={location?.pathname === menu.url}
            button
            onClick={handleNavigation(menu.url)}
          >
            <ListItemIcon sx={LIST_ICON_STYLES}>
              <MenuIcon
                fontSize="small"
                sx={{
                  color:
                    location?.pathname === menu.url
                      ? "primary.main"
                      : "default",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={menu.name}
              primaryTypographyProps={{
                visibility: showListItemsTitle ? "visible" : "hidden",
                color:
                  location?.pathname === menu.url ? "primary.main" : "default",
                fontWeight: location?.pathname === menu.url ? 600 : 400,
              }}
            />
          </CustomListItem>
        ) : (
          <WithSubMenus
            menu={menu}
            index={index}
            key={menu.name}
            showListItemsTitle={showListItemsTitle}
          >
            <List component="div" dense sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
              {menu.submenu.map((submenu) => {
                const Icon = getIcon(submenu.icon);
                const isActive = location?.pathname === submenu.url;

                return (
                  <CustomListItem
                    button
                    active={isActive}
                    sx={{ pl: 2.5, mb: 0.5 }}
                    key={submenu.name}
                    onClick={() => (window.location.href = submenu.url)}
                  >
                    <ListItemIcon sx={LIST_ICON_STYLES}>
                      <Icon
                        fontSize="small"
                        sx={{
                          color: isActive ? "primary.main" : "default",
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={submenu.name}
                      primaryTypographyProps={{
                        visibility: showListItemsTitle ? "visible" : "hidden",
                        color: isActive ? "primary.main" : "default",
                        fontWeight: isActive ? 600 : 400,
                      }}
                    />
                  </CustomListItem>
                );
              })}
            </List>
          </WithSubMenus>
        )}
      </List>
    );
  });
};

export default React.memo(SidebarMenu);
