import { Routes, Route } from "react-router-dom";

import Login from "../pages/Login";

import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";

import ListCompanies from "../pages/Companies/List";
import FormCompanies from "../pages/Companies/Form";

import ListUserGroups from "../pages/UserGroups/List";
import FormUserGroups from "../pages/UserGroups/Form";

import ListUsers from "../pages/Users/List";
import FormUsers from "../pages/Users/Form";

import ListCameras from "../pages/Cameras/List";
import FormCameras from "../pages/Cameras/Form";

import ListTags from "../pages/Tags/List";
import FormTags from "../pages/Tags/Form";

import ListFiles from "../pages/Files/List";
import FormFiles from "../pages/Files/Form";
import Downloads from "../pages/Files/Downloads";
import DownloadsLogs from "../pages/Files/DownloadsLogs";

import AccessLogs from "../pages/Users/AccessLogs";

import ListStorages from "../pages/Storages/List";
import FormStorages from "../pages/Storages/Form";

import ScanLogs from "../pages/ScanLogs";

import { ROUTES } from "../config/routes";

const Router = () => {
  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<ListFiles />} />
      <Route path={ROUTES.FILES} element={<ListFiles />} />
      <Route path={ROUTES.DOWNLOADS} element={<Downloads />} />
      <Route path={ROUTES.DOWNLOADS_LOGS} element={<DownloadsLogs />} />
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.FORGOT} element={<ForgotPassword />} />
      <Route path={ROUTES.RESETPASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.ACCESS_LOGS} element={<AccessLogs />} />

      {/** FILES */}
      <Route path={ROUTES.ADMIN.ADD_FILES} element={<FormFiles />} />

      {/** COMPANIES */}
      <Route path={ROUTES.ADMIN.COMPANIES} element={<ListCompanies />} />
      <Route path={ROUTES.ADMIN.COMPANIES_CREATE} element={<FormCompanies />} />
      <Route path={ROUTES.ADMIN.COMPANIES_EDIT} element={<FormCompanies />} />

      {/** USER GROUPS */}
      <Route path={ROUTES.ADMIN.USER_GROUPS} element={<ListUserGroups />} />
      <Route
        path={ROUTES.ADMIN.USER_GROUPS_CREATE}
        element={<FormUserGroups />}
      />
      <Route
        path={ROUTES.ADMIN.USER_GROUPS_EDIT}
        element={<FormUserGroups />}
      />

      {/** USERS */}
      <Route path={ROUTES.ADMIN.USERS} element={<ListUsers />} />
      <Route path={ROUTES.ADMIN.USERS_CREATE} element={<FormUsers />} />
      <Route path={ROUTES.ADMIN.USERS_EDIT} element={<FormUsers />} />

      {/** ScanLogs */}
      <Route path={ROUTES.ADMIN.SCAN_LOGS} element={<ScanLogs />} />

      {/** CAMERAS */}
      <Route path={ROUTES.CONFIG.CAMERAS} element={<ListCameras />} />
      <Route path={ROUTES.CONFIG.CAMERAS_CREATE} element={<FormCameras />} />
      <Route path={ROUTES.CONFIG.CAMERAS_EDIT} element={<FormCameras />} />

      {/** TAGS */}
      <Route path={ROUTES.CONFIG.TAGS} element={<ListTags />} />
      <Route path={ROUTES.CONFIG.TAGS_CREATE} element={<FormTags />} />
      <Route path={ROUTES.CONFIG.TAGS_EDIT} element={<FormTags />} />

      {/** Storages */}
      <Route path={ROUTES.CONFIG.STORAGES} element={<ListStorages />} />
      <Route path={ROUTES.CONFIG.STORAGES_CREATE} element={<FormStorages />} />
      <Route path={ROUTES.CONFIG.STORAGES_EDIT} element={<FormStorages />} />
    </Routes>
  );
};

export default Router;
