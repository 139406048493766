import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useIntl, defineMessages } from "react-intl";

import logoBig from "assets/images/inspecionarLogoBlue.png";
import logoIcon from "assets/images/inspecionarLogoIcon.png";

import SidebarMenus from "./SidebarMenus";

import { useAuthenticationConsumer } from "shared/providers/Authentication";

const drawerWidth = 280;

const messages = defineMessages({
  exit: { id: "exit" },
});

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin", "left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin", "left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const FIXED = "FIXED";

export default function MiniDrawer({ children }) {
  const { formatMessage } = useIntl();
  const [fixed, setFixed] = React.useState(() => {
    return localStorage.getItem(FIXED) === FIXED;
  });
  const [open, setOpen] = React.useState(fixed);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const { signOut, data } = useAuthenticationConsumer();

  const handleFixedDrawer = () => {
    if (fixed) {
      localStorage.removeItem(FIXED);
      setFixed(false);
      setOpen(false);
    } else {
      localStorage.setItem(FIXED, FIXED);
      setFixed(true);
      setOpen(true);
    }
  };

  const openDrawerOnMouseEnter = () => {
    if (fixed) return;
    setOpen(true);
  };

  const closeDrawerOnMouseLeave = () => {
    if (fixed) return;
    setOpen(false);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const renderMenuProfile = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={isMenuOpen}
      onClose={closeMenu}
      onClick={closeMenu}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: 2,
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          ".MuiTypography-root, .MuiMenuItem-root": {
            fontSize: "0.875rem",
            lineHeight: "1.57143",
          },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box sx={{ pl: 2, pr: 2 }}>
        <Typography
          sx={{ fontWeight: "bold" }}
        >{`${data.user.name}`}</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {data.user.email}
        </Typography>
      </Box>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <MenuItem onClick={signOut}>
        <Typography color="error">{formatMessage(messages.exit)}</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        open={open}
        sx={{
          width: "auto",
          left: !open ? "calc(72px + 1px)" : 0,
          pt: 1.75,
          pb: 1.75,
        }}
      >
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <Box sx={{ pl: 2, pr: 2 }}>
            <Tooltip title="Minha conta">
              <IconButton
                onClick={openMenu}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          {renderMenuProfile}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={openDrawerOnMouseEnter}
        onMouseLeave={closeDrawerOnMouseLeave}
      >
        <DrawerHeader
          sx={{ justifyContent: open ? "space-between" : "center" }}
        >
          <img
            src={open ? logoBig : logoIcon}
            width={open ? 175 : 28}
            alt="inspecionar-logo"
          />
          {open && (
            <IconButton onClick={handleFixedDrawer}>
              {fixed ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          )}
        </DrawerHeader>

        <SidebarMenus showListItemsTitle={open} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 14.5,
          ml: "auto",
          mr: "auto",
          pr: 2,
          pb: 14.5,
          pl: 2,
          overflowX: "hidden",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
