import React from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import { logs } from "modules/files/services";
import { IFileLog } from "modules/files/types";
import { formatDate } from "shared/parsers/date";
import logMessages from "shared/constants/logs";

const DownloadsLogs: React.FC = () => {
  const [data, setData] = React.useState<IFileLog[]>([]);
  const handleData = React.useCallback(async () => {
    const { status, data: dataLogs } = await logs();

    if (status === 200) {
      setData(dataLogs);
    }
  }, []);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <Container maxWidth={false}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 5, overflowX: "auto" }}
      >
        <Typography variant="h5" component="h4" fontWeight="bold">
          Registros de downloads
        </Typography>
      </Grid>
      <Paper
        sx={{
          width: "100%",
          borderRadius: 4,
          boxShadow:
            "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        }}
      >
        <TableContainer>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Usuário</TableCell>
                <TableCell>Ação</TableCell>
                <TableCell>Arquivo</TableCell>
                <TableCell>Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: IFileLog) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>{`${row.user.name} ${row.user.surname}`}</TableCell>
                    <TableCell>
                      {logMessages[row.log_type] || "Não definido"}
                    </TableCell>
                    <TableCell>{row.file.name}</TableCell>
                    <TableCell>{formatDate(row.created_at)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export { DownloadsLogs };
