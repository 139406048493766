import React from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Container,
  Button,
} from "@mui/material";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { useForm } from "react-hook-form";
import { IUserGroup } from "modules/user-groups/types";
import useCrud from "shared/hooks/use-crud";
import { store, setURI } from "shared/services/crud";

const messages = defineMessages({
  title: { id: "user.groups.create" },
  create: { id: "create" },
  save: { id: "save" },
  required: { id: "required" },
  success: { id: "crud.register.success" },
  error: { id: "crud.register.error" },
  cancel: { id: "cancel" },
});

const Header: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 5, overflowX: "auto" }}
    >
      <Typography variant="h5" component="h4" fontWeight="bold">
        {formatMessage(messages.title)}
      </Typography>
    </Grid>
  );
};

const initialValues: IUserGroup = {
  name: " ",
  uid: " ",
};

const FormUserGroups: React.FC = () => {
  const uri = "/user-groups";
  setURI(uri);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getById, editData } = useCrud<IUserGroup>({
    uri,
    initialValues,
  });
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<IUserGroup>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  React.useEffect(() => {
    getById(Number(id));
  }, [getById, id]);

  React.useEffect(() => {
    reset({ ...editData });
  }, [editData, reset]);

  const onSubmit = async (values: IUserGroup) => {
    return store(values)
      .then(() => {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        navigate(ROUTES.ADMIN.USER_GROUPS);
      })
      .catch(() =>
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        })
      );
  };

  return (
    <Container maxWidth="lg">
      <Header />
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        boxShadow={1}
        style={{ display: "grid", gridAutoFlow: "row" }}
        sx={{ gap: 3, padding: 3, borderRadius: 4 }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            label="Nome"
            fullWidth
            {...register("name", { required: true })}
            error={Boolean(errors.name)}
            helperText={
              errors.name?.type === "required" &&
              formatMessage(messages.required)
            }
          />
        </div>
        <div>
          <TextField
            label="UID"
            fullWidth
            {...register("uid", { required: true })}
            error={Boolean(errors.uid)}
            helperText={
              errors.uid?.type === "required" &&
              formatMessage(messages.required)
            }
          />
        </div>
        <Box sx={{ mt: 3, textAlign: "right" }}>
          <Button
            type="button"
            onClick={() => navigate(ROUTES.ADMIN.USER_GROUPS)}
          >
            {formatMessage(messages.cancel)}
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" type="submit">
            {formatMessage(id ? messages.save : messages.create)}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export { FormUserGroups };
