import React from "react";
import { ListUserGroups } from "modules/user-groups/components/List";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page = function (): React.ReactElement {
  return <ListUserGroups />;
};

export default withAuthenticationRequired(withSideBar(Page));
