import React from "react";

import { FormUsers } from "modules/users/components/Form";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <FormUsers />;
};

export default withAuthenticationRequired(withSideBar(Page));
