import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#1390cb",
      main: "#1390cb",
      dark: "#303f9f",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: ["Public Sans"].join(","),
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: "100% !important",
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiInputBase-root": {
            borderRadius: 8,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          fontSize: 14,
          "&.Mui-disabled": {
            pointerEvents: "auto",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            pointerEvents: "auto",
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 8,
          "&:hover, &:active": {
            ".MuiListItem-button": {
              borderRadius: 8,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover, &:active": {
            borderRadius: 8,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(244, 246, 248)",
          color: "rgb(99, 115, 129)",
          ".MuiTableCell-root": {
            "&:first-of-type": {
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              paddingLeft: 24,
              boxShadow: "rgb(255 255 255) 8px 0px 0px inset",
            },
            "&:last-of-type": {
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              paddingRight: 24,
              boxShadow: "rgb(255 255 255) -8px 0px 0px inset",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
  },
});

export { theme };
