import React from "react";
import { FileFiltersProvider } from "../../providers/FileFilters";
import { FilesDashboard } from "./dashboard";

const Files: React.FC = () => {
  return (
    <FileFiltersProvider>
      <FilesDashboard />
    </FileFiltersProvider>
  );
};

export { Files };
