import React from "react";
import { ITag } from "modules/tags/types";
import { all } from "modules/tags/services";

const useTags = () => {
  const [tags, setTags] = React.useState<ITag[]>([]);

  const getAll = React.useCallback(async () => {
    const { data } = await all();
    setTags(data);
  }, []);

  React.useEffect(() => {
    getAll();
  }, [getAll]);

  return { tags };
};

export { useTags };
