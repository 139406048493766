import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Skeleton,
  Divider,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useIntl, defineMessages } from "react-intl";
import { useNavigate } from "react-router-dom";
import useCrud from "shared/hooks/use-crud";
import { TCell, Options } from "./TableCell";

const messages = defineMessages({
  add: { id: "add" },
  noRecords: { id: "no-records" },
});

interface IList {
  title: string;
  columns: any;
  uri: string;
  filters?: any;
}

const List: React.FC<IList> = ({ title, columns, uri, filters }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { get, deleteById, data, meta, isEmpty, isLoading } = useCrud({
    uri,
    filters,
  });

  const goToAdd = () => navigate(`${uri}/create`, { replace: true });

  const handleChangePage = (event: unknown, newPage: number) => {
    get(newPage + 1);
  };

  React.useEffect(() => {
    get();
  }, [get]);

  if (isLoading) return <Skeleton variant="rectangular" height={350} />;

  if (isEmpty) {
    return (
      <>
        <h1>{title}</h1>
        <h4>{formatMessage(messages.noRecords)}</h4>
        <Button variant="contained" startIcon={<AddIcon />} onClick={goToAdd}>
          {formatMessage(messages.add)}
        </Button>
      </>
    );
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 5, overflowX: "auto" }}
      >
        <Typography variant="h5" component="h4" fontWeight="bold">
          {title}
        </Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={goToAdd}>
          {formatMessage(messages.add)}
        </Button>
      </Grid>
      <Paper
        sx={{
          width: "100%",
          borderRadius: 4,
          boxShadow:
            "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        }}
      >
        <TableContainer>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((row: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column: any) => (
                        <TCell key={column.id} row={row} column={column}>
                          <Options
                            id={row.id}
                            uri={uri}
                            column={column}
                            onRemove={deleteById}
                          />
                        </TCell>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />
        {meta && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[meta.per_page]}
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
          />
        )}
      </Paper>
    </>
  );
};

export { List };
