import React from "react";
import { ListTags } from "modules/tags/components/List";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <ListTags />;
};

export default withAuthenticationRequired(withSideBar(Page));
