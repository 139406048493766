import React from "react";
import { recovery } from "shared/services";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { FormBox } from "modules/auth/components/FormBox";

const messages = defineMessages({
  title: { id: "password.recovery" },
  subtitle: { id: "send.recovery.email" },
  email: { id: "email" },
  sendEmail: { id: "send" },
  loading: { id: "loading" },
  back: { id: "back" },
  recoveryEmailSuccess: { id: "password.recovery.success" },
});

type FieldValues = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Digite um email válido")
      .required("Campo obrigatório"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({ mode: "onBlur", resolver: yupResolver(schema) });

  const goBack = React.useCallback(() => navigate(ROUTES.LOGIN), [navigate]);

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const { status } = await recovery(data.email);
    if (status === 200) {
      setLoading(false);
      setSuccess(true);
    }
  };
  return (
    <FormBox
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.subtitle)}
    >
      <div className="childrenForm">
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          sx={{
            paddingTop: 4,
            width: "100%",
          }}
        >
          {!success && (
            <>
              <TextField
                {...register("email", { required: true })}
                label={formatMessage(messages.email)}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
                fullWidth
              />

              <Button
                variant="contained"
                type="submit"
                size="large"
                fullWidth
                sx={{ mt: 2 }}
                disabled={loading}
              >
                {formatMessage(loading ? messages.loading : messages.sendEmail)}
              </Button>
            </>
          )}
          {success && (
            <Typography
              align="center"
              sx={{ marginTop: 2 }}
              style={{ color: "#28686a", fontWeight: "bold" }}
            >
              {formatMessage(messages.recoveryEmailSuccess)}
            </Typography>
          )}
        </Box>
        <Button
          onClick={goBack}
          variant="text"
          size="large"
          fullWidth
          sx={{ mt: 2 }}
        >
          {formatMessage(messages.back)}
        </Button>
      </div>
    </FormBox>
  );
};

export { ForgotPassword };
