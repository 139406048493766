import React from "react";
import { Box } from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import { InputGroup, Input, MultiCascader } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useCompanies } from "shared/hooks/useCompanies";
import { ICompany } from "modules/companies/types";

interface IProps {
  onChange: (values: IFilters) => void;
}

export interface IFilters {
  query: string;
  companyIds: number[];
}

interface IKeyValue {
  label: string;
  value: number;
}

const UserFilters = ({ onChange }: IProps) => {
  const { companies } = useCompanies(true);
  const [filters, setFilters] = React.useState<IFilters>({} as IFilters);
  const [localCompanies, setLocalCompanies] = React.useState<IKeyValue[]>([]);

  React.useEffect(() => {
    const cm: IKeyValue[] = [];
    companies.forEach((company: ICompany) =>
      cm.push({ label: company.fantasy_name, value: company.id || 0 })
    );
    setLocalCompanies(cm);
  }, [companies]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #CCC",
      }}
    >
      <form
        style={{ display: "flex", width: "100%" }}
        onSubmit={(e) => e.preventDefault()}
      >
        <div style={{ width: "50%", marginRight: 10 }}>
          <label>
            <b>Buscar por nome ou email</b>
          </label>
          <InputGroup>
            <Input
              placeholder=""
              value={filters.query}
              style={{ width: "100%" }}
              onChange={(query: string) => {
                setFilters({ ...filters, query });
                onChange({ ...filters, query });
              }}
            />
            <InputGroup.Addon>
              <SearchIcon />
            </InputGroup.Addon>
          </InputGroup>
        </div>
        <div style={{ width: "25%", marginRight: 10 }}>
          <label>
            <b>Empresa</b>
          </label>
          <MultiCascader
            menuWidth={250}
            cascade={false}
            data={localCompanies}
            placeholder="Filtrar por empresa"
            style={{ width: "100%" }}
            onChange={(companyIds: number[]) => {
              setFilters({ ...filters, companyIds });
              onChange({ ...filters, companyIds });
            }}
          />
        </div>
      </form>
    </Box>
  );
};

export { UserFilters };
