import React from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { IUser } from "modules/users/types";
import { formatPhone } from "shared/parsers/phone";
import { ROUTES } from "config/routes";
import { useNavigate } from "react-router-dom";

export interface IUserCompanyRow {
  user: IUser;
  onDisconnect: (userId: number) => void;
}

const UserCompanyRow: React.FC<IUserCompanyRow> = ({ onDisconnect, user }) => {
  const navigate = useNavigate();
  const { id, avatar, name, surname, email, cellphone } = user;
  return (
    <List dense={true}>
      <ListItem
        secondaryAction={
          <Tooltip title="Desassociar">
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onDisconnect(id || 0)}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <ListItemAvatar
          style={{ cursor: "pointer" }}
          onClick={() => navigate(`${ROUTES.ADMIN.USERS}/edit/${id}`)}
        >
          <Avatar
            alt={`${name} ${surname}`}
            src={avatar || `${name[0]}${surname[0]}`}
          />
        </ListItemAvatar>
        <ListItemText
          primary={`${name} ${surname}`}
          secondary={`${email} | ${formatPhone(cellphone || "")}`}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
};

export { UserCompanyRow };
