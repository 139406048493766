import axiosInstance from "shared/services";
import { IUserAcessLogsResponse } from "../types";

const uri: string = "users";

export const all = async (): Promise<any> =>
  axiosInstance.get(`${uri}/all`).then();

export const usersCompany = async (companyId: number): Promise<any> =>
  axiosInstance.get(`${uri}/company/${companyId}`).then();

export const connectUser = async (data: {
  userId: number;
  companyId: number;
}): Promise<any> => {
  return await axiosInstance.patch(
    `${uri}/${data.userId}/company/connect/${data.companyId}`,
    data
  );
};

export const disconnectUser = async (data: {
  userId: number;
  companyId: number;
}): Promise<any> => {
  return await axiosInstance.patch(
    `${uri}/${data.userId}/company/disconnect/${data.companyId}`,
    data
  );
};

export const logs = async (): Promise<IUserAcessLogsResponse> => {
  return await axiosInstance.get("/access-logs");
};
