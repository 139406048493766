import React from "react";
import { ListCompanies } from "modules/companies/components/List";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page = function (): React.ReactElement {
  return <ListCompanies />;
};

export default withAuthenticationRequired(withSideBar(Page));
