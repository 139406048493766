const commom = (key: string, type?: string) => {
  if (!type) return false;
  return type === key;
};

export const isRequiredError = (type?: string): boolean => {
  return commom("required", type);
};

export const isPatterError = (type?: string): boolean => {
  return commom("pattern", type);
};

export const isMinLengthError = (type?: string): boolean => {
  return commom("minLength", type);
};

export const hasError = (
  key: string,
  errors?: { [x: string]: any }
): boolean => {
  if (!errors) return false;
  return Boolean(errors[key]);
};
