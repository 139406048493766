import React from "react";

import { FormStorages } from "modules/storages/components/Form";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <FormStorages />;
};

export default withAuthenticationRequired(withSideBar(Page));
