import React from "react";
import { ICamera } from "modules/cameras/types";
import { all } from "modules/cameras/services";

const useCameras = () => {
  const [cameras, setCameras] = React.useState<ICamera[]>([]);

  const getAll = React.useCallback(async () => {
    const { data } = await all();
    setCameras(data);
  }, []);

  React.useEffect(() => {
    getAll();
  }, [getAll]);

  return { cameras };
};

export { useCameras };
