import {
  Settings,
  ApartmentOutlined,
  AdminPanelSettings,
  Group,
  Person,
  CloudDoneOutlined,
  CameraAltOutlined,
  LocalOfferOutlined,
  StorageOutlined,
  AddCircleOutlineOutlined,
  SmartToy,
  CloudDownloadOutlined,
  Home,
  AttachFileOutlined,
  TimelapseOutlined,
} from "@mui/icons-material";

export const ICONS_LABEL = {
  Settings: "Settings",
  ApartmentOutlined: "ApartmentOutlined",
  AdminPanelSettings: "AdminPanelSettings",
  Group: "Group",
  Person: "Person",
  CloudDoneOutlined: "CloudDoneOutlined",
  CameraAltOutlined: "CameraAltOutlined",
  LocalOfferOutlined: "LocalOfferOutlined",
  StorageOutlined: "StorageOutlined",
  AddCircleOutlineOutlined: "AddCircleOutlineOutlined",
  SmartToy: "SmartToy",
  CloudDownloadOutlined: "CloudDownloadOutlined",
  Home: "Home",
  AttachFileOutlined: "AttachFileOutlined",
  TimelapseOutlined: "TimelapseOutlined",
};

export const ICONS = {
  [ICONS_LABEL.Settings]: Settings,
  [ICONS_LABEL.ApartmentOutlined]: ApartmentOutlined,
  [ICONS_LABEL.AdminPanelSettings]: AdminPanelSettings,
  [ICONS_LABEL.Group]: Group,
  [ICONS_LABEL.Person]: Person,
  [ICONS_LABEL.CloudDoneOutlined]: CloudDoneOutlined,
  [ICONS_LABEL.CameraAltOutlined]: CameraAltOutlined,
  [ICONS_LABEL.LocalOfferOutlined]: LocalOfferOutlined,
  [ICONS_LABEL.StorageOutlined]: StorageOutlined,
  [ICONS_LABEL.AddCircleOutlineOutlined]: AddCircleOutlineOutlined,
  [ICONS_LABEL.SmartToy]: SmartToy,
  [ICONS_LABEL.CloudDownloadOutlined]: CloudDownloadOutlined,
  [ICONS_LABEL.Home]: Home,
  [ICONS_LABEL.AttachFileOutlined]: AttachFileOutlined,
  [ICONS_LABEL.TimelapseOutlined]: TimelapseOutlined,
};

export const getIcon = (iconLabel: string) =>
  ICONS[iconLabel] ?? ApartmentOutlined;
