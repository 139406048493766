import React from "react";
import { Grid, Paper, CircularProgress } from "@mui/material";
import {
  AttachFileOutlined,
  CalendarMonthOutlined,
  UploadFileOutlined,
  SearchOutlined,
} from "@mui/icons-material";

import { humanFileSize } from "shared/parsers/filesize";

interface IProps {
  data: {
    totalSearch: number;
    totalSize: number;
    totalDay: number;
    totalWeek: number;
    totalMonth: number;
    totalFiles: number;
  };
  loading: boolean;
}

const BiResume: React.FC<IProps> = ({ data, loading }) => {
  const dashboard = [
    {
      title: "Total pesquisado",
      icon: <SearchOutlined style={{ color: "#77b366" }} />,
      value: (data && data.totalSearch) || 0,
    },
    {
      title: "Tamanho pesquisado",
      icon: <AttachFileOutlined style={{ color: "#77b366" }} />,
      value: (data && humanFileSize(data.totalSize)) || 0,
    },
    {
      title: "Total do mês",
      icon: <CalendarMonthOutlined style={{ color: "#77b366" }} />,
      value: (data && data.totalMonth) || 0,
    },
    {
      title: "Total de arquivos",
      icon: <UploadFileOutlined style={{ color: "#77b366" }} />,
      value: (data && data.totalFiles) || 0,
    },
  ];

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 5, overflowX: "auto" }}
    >
      {dashboard.map((item, index) => (
        <Paper
          key={`idx_${index}`}
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "25px 55px",
            border: "1px solid #EEE",
            borderRadius: "10px",
          }}
        >
          {!loading ? (
            <>
              <div
                style={{
                  fontSize: "28px",
                  fontWeight: "bold",
                  color: "#414452",
                }}
              >
                {item.icon} {item.value}
              </div>
              <p>{item.title}</p>
            </>
          ) : (
            <CircularProgress />
          )}
        </Paper>
      ))}
    </Grid>
  );
};

export { BiResume };
