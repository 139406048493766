import React from "react";
import { Grid, Typography } from "@mui/material";

interface IFormHead {
  title: string;
}

const FormHead: React.FC<IFormHead> = ({ title }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 5, overflowX: "auto" }}
    >
      <Typography variant="h5" component="h4" fontWeight="bold">
        {title}
      </Typography>
    </Grid>
  );
};

export { FormHead };
