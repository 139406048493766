import React from "react";
import { Container } from "@mui/material";
import { ROUTES } from "config/routes";
import { List } from "shared/components/List";
import { useIntl, defineMessages } from "react-intl";
import { columns } from "./columns";
import { CompanyFilters, IFilters } from "../CompanyFilters";

const messages = defineMessages({
  title: { id: "companies" },
});

const ListCompanies: React.FC = () => {
  const { formatMessage } = useIntl();
  const [filters, setFilters] = React.useState<IFilters>({} as IFilters);
  return (
    <Container maxWidth={false}>
      <CompanyFilters onChange={(values) => setFilters(values)} />
      <List
        title={formatMessage(messages.title)}
        columns={columns}
        uri={ROUTES.ADMIN.COMPANIES}
        filters={filters}
      />
    </Container>
  );
};

export { ListCompanies };
