import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import IntlProvider from "shared/providers/Intl";
import { AuthenticationProvider } from "shared/providers/Authentication";
import SnackbarProvider from "shared/providers/Snackbar";

import { theme } from "./config/theme";
import Routes from "./routes";

const App = function (): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <IntlProvider>
          <SnackbarProvider>
            <AuthenticationProvider>
              <Routes />
            </AuthenticationProvider>
          </SnackbarProvider>
        </IntlProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
