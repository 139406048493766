import React from "react";

import { login } from "shared/services";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { Authenticated } from "shared/types";
import PROPERTIES from "config/properties";
import {
  getAuthenticatedUser,
  clearAuthenticatedUser,
  setAuthenticatedUser,
} from "shared/parsers/localstorage";
import { getDifferenceInMinutes } from "shared/parsers/date";
import firebase from "firebase/app";
import "firebase/auth";

firebase.initializeApp({
  apiKey: PROPERTIES.FIREBASE_API_KEY,
  authDomain: PROPERTIES.FIREBASE_AUTH_DOMAIN,
  projectId: PROPERTIES.FIREBASE_PROJECT_ID,
  storageBucket: PROPERTIES.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: PROPERTIES.FIREBASE_MESSAGING_SENDER_ID,
  appId: PROPERTIES.FIREBASE_APP_ID,
});
export interface IContext {
  isAuthenticated: boolean;
  data: Authenticated;
  signIn: (email: string, password: string, callback?: () => void) => void;
  signOut: () => void;
  error: Error;
  isLoading: boolean;
  showSMS: boolean;
  setShowSMS: (value: boolean) => void;
  captchaRef: any;
  setSmsCode: (value: string) => void;
  handleSubmitSms: () => void;
  blocked: boolean;
  setBlocked: (blocked: boolean) => void;
}

const Context = React.createContext({} as IContext);

const TOKEN_VALID_MINUTES = 120;

export interface IAuthenticationProvider {
  children: any;
}

const AuthenticationProvider: React.FC<IAuthenticationProvider> = ({
  children,
}) => {
  const captchaRef = React.useRef(null);
  const { openSnackbar } = useSnackbarConsumer();
  const [error, setError] = React.useState<Error>({} as Error);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showSMS, setShowSMS] = React.useState<boolean>(false);
  const [smsCode, setSmsCode] = React.useState<string>("");
  const [authInfo, setAuthInfo] = React.useState<Authenticated>(
    {} as Authenticated
  );
  const [blocked, setBlocked] = React.useState<boolean>(false);
  const [data, setData] = React.useState<Authenticated>(() => {
    const authenticated = getAuthenticatedUser();

    if (authenticated.auth) {
      const isTokenExpired =
        getDifferenceInMinutes(
          new Date(),
          new Date(authenticated.auth.expires_at)
        ) >= TOKEN_VALID_MINUTES;

      if (isTokenExpired) {
        clearAuthenticatedUser();
        return {} as Authenticated;
      }
    }

    return authenticated;
  });

  const signIn = React.useCallback(
    async (email: string, password: string, callback?: () => void) => {
      try {
        setIsLoading(true);

        const response: any = await login(email, password);

        if (response && response.status && response.status === 402) {
          setBlocked(true);
        } else {
          const user = {
            name: email.split("@")[0],
            email,
            cellphone: response.cellphone,
          };
          const auth = {
            auth: response,
            user,
          } as unknown as Authenticated;

          setShowSMS(true);
          setAuthInfo(auth);
          handleSMS(user.cellphone);
        }
        callback?.();
      } catch (e) {
        setError(e as Error);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const handleSMS = async (cellphone: string) => {
    try {
      //@ts-ignore
      if (!window.reCaptchaVerifier) {
        //@ts-ignore
        window.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
          }
        );
        //@ts-ignore
        window.reCaptchaVerifier.render();
        //@ts-ignore
        setTimeout(() => captchaRef.current.click(), 3000);
      }

      cellphone = cellphone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");

      return (
        firebase
          .auth()
          //@ts-ignore
          .signInWithPhoneNumber(`+55${cellphone}`, window.reCaptchaVerifier)
          .then((confirmationResult) => {
            //@ts-ignore
            window.confirmationResult = confirmationResult;
            //@ts-ignore
            window.reCaptchaVerifier.reset();
          })
          .catch((error) => {
            //@ts-ignore
            window.reCaptchaVerifier.reset();
          })
      );
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmitSms = async () => {
    try {
      setIsLoading(true);
      //@ts-ignore
      window.confirmationResult
        .confirm(smsCode)
        .then(async () => {
          openSnackbar({
            message: "Validado com sucesso! Aguarde...",
            severity: "success",
          });
          setAuthenticatedUser(authInfo);
          window.location.href = "/";
        })
        .catch(() => {
          openSnackbar({
            message: "Código inválido ou número de tentativas excedidas.",
            severity: "error",
          });
          setIsLoading(false);
        });
    } catch (e: any) {
      console.log(e.message);
      setIsLoading(false);
    }
  };

  const signOut = React.useCallback(() => {
    clearAuthenticatedUser();
    setData({} as Authenticated);
  }, []);

  const value = React.useMemo(
    () => ({
      data,
      isAuthenticated: Boolean(data.auth?.token && data.user),
      signIn,
      signOut,
      error,
      isLoading,
      showSMS,
      setShowSMS,
      captchaRef,
      setSmsCode,
      handleSubmitSms,
      blocked,
      setBlocked,
    }),
    [
      data,
      signIn,
      signOut,
      error,
      isLoading,
      showSMS,
      setShowSMS,
      captchaRef,
      setSmsCode,
      handleSubmitSms,
      blocked,
      setBlocked,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAuthenticationConsumer = (): IContext =>
  React.useContext<IContext>(Context);

export const { Consumer } = Context;

export { AuthenticationProvider };
