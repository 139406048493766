import React from "react";
import { ScanLogsDashboard } from "modules/scan-logs/components/ScanLogsDashboard";
import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <ScanLogsDashboard />;
};

export default withAuthenticationRequired(withSideBar(Page));
