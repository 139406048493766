import styled from "@emotion/styled";

export const ContainerVideo = styled.div`
  iframe {
    width: 720px !important;
    height: 420px !important;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 10px 20px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
`;
