import React from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Box,
  Container,
  Button,
  Autocomplete,
  LinearProgress,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import {
  HighlightOffOutlined,
  CloudDownloadOutlined,
} from "@mui/icons-material";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useNavigate } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";

import { useFileTypes } from "shared/hooks/useFileTypes";
import { useCameras } from "shared/hooks/useCameras";
import { useStorages } from "shared/hooks/useStorages";

import { IFileType } from "modules/file-types/types";
import { ICamera } from "modules/cameras/types";
import { IStorage } from "modules/storages/types";

import { FormHead } from "shared/components/Form/Header";
import { store, downloadFile } from "modules/files/services";

import * as validations from "shared/validations/form";
import { IFile } from "modules/files/types";

const messages = defineMessages({
  titleCreate: { id: "files.create" },
  titleEdit: { id: "files.edit" },
  create: { id: "create" },
  save: { id: "save" },
  required: { id: "required" },
  success: { id: "files.upload.success" },
  error: { id: "files.upload.error" },
  cancel: { id: "cancel" },
  send: { id: "send" },
  downloadError: { id: "files.download.error" },
  downloadSuccess: { id: "files.download.success" },
});

interface IForm {
  id?: number;
  file_type_id: number;
  camera_id: number;
  name: string;
  file: File;
  storages: IStorage[];
}

const FormFiles: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [resultFiles, setResultFiles] = React.useState<IFile[]>([]);
  const { fileTypes } = useFileTypes();
  const { cameras } = useCameras();
  const { storages } = useStorages();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const onDrop = React.useCallback(
    (file) => {
      setValue("file", file[0]);
    },
    [setValue]
  );

  const watchFile: File = watch("file");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    const data: any = { ...values };

    if (data.storages) {
      const ids: number[] = [];
      data.storages.filter((storage: IStorage) => ids.push(storage.id || 0));
      data.storages = ids;
    }

    const { status, data: dataFile } = await store(data);
    if (status === 200) {
      openSnackbar({
        message: formatMessage(messages.success),
        severity: "success",
      });
      setResultFiles(dataFile);
    } else {
      openSnackbar({
        message: formatMessage(messages.error),
        severity: "error",
      });
    }
    setLoading(false);
  };

  const handleDownload = async (id: number) => {
    setLoading(true);
    const { status, data: dataDownload } = await downloadFile(id);
    if (status === 200) {
      window.open(dataDownload.url);
      openSnackbar({
        message: formatMessage(messages.downloadSuccess),
        severity: "success",
      });
    } else {
      openSnackbar({
        message: formatMessage(messages.downloadError),
        severity: "error",
      });
    }
    setLoading(false);
  };

  return (
    <Container maxWidth="lg">
      <FormHead title="Enviar novo arquivo" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "40%" }}>
            <Box sx={{ marginBottom: "10px" }}>
              <TextField
                label="Nome*"
                fullWidth
                {...register("name", { required: true })}
                error={Boolean(errors.name)}
                helperText={
                  errors.name?.type === "required" &&
                  formatMessage(messages.required)
                }
                disabled={loading}
              />
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="file-type-id">Tipo do arquivo*</InputLabel>
                <Select
                  id="file-type-id"
                  placeholder="Selecione um formato"
                  fullWidth
                  error={Boolean(errors.file_type_id)}
                  disabled={loading}
                  {...register("file_type_id", { required: true })}
                >
                  {fileTypes.map((fileType: IFileType) => (
                    <MenuItem value={fileType.id}>{fileType.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginBottom: "10px" }}>
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="storages"
                  rules={{ required: true }}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <Autocomplete
                      autoHighlight
                      autoSelect
                      multiple
                      options={storages}
                      getOptionLabel={(label) => label.name}
                      onChange={(_, selected?) => {
                        onChange(selected);
                      }}
                      disabled={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={invalid}
                          helperText={
                            validations.isRequiredError(error?.type) &&
                            formatMessage(messages.required)
                          }
                          label={"Selecione os armazenadores"}
                          disabled={loading}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="camera-id">Câmera</InputLabel>
                <Select
                  id="camera-id"
                  placeholder="Selecione uma camera"
                  fullWidth
                  error={Boolean(errors.camera_id)}
                  disabled={loading}
                  {...register("camera_id", { required: false })}
                >
                  {cameras.map((camera: ICamera) => (
                    <MenuItem value={camera.id}>{camera.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "20px",
              borderWidth: "2px",
              borderRadius: "2px",
              borderColor: `${Boolean(errors.file) ? "red" : "#eeeeee"}`,
              borderStyle: "dashed",
              backgroundColor: "#fafafa",
              marginLeft: "20px",
              color: "#bdbdbd",
              outline: "none",
              transition: "border .24s ease-in-out",
            }}
          >
            <div
              {...getRootProps()}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {watchFile && watchFile.name ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "10px",
                    border: "1px solid #CCC",
                  }}
                >
                  <p>{watchFile.name}</p>
                  <Button
                    type="button"
                    onClick={() => setValue("file", {} as File)}
                  >
                    <HighlightOffOutlined />
                  </Button>
                </div>
              ) : (
                <>
                  <input
                    {...getInputProps()}
                    type="file"
                    {...register("file", {
                      validate: (value) => value.name,
                    })}
                    disabled={loading}
                  />
                  {isDragActive ? (
                    <>Solte o seu arquivo aqui...</>
                  ) : (
                    <>
                      Arraste e solte o seu arquivo aqui, ou clique para
                      selecionar
                    </>
                  )}
                </>
              )}
            </div>
          </Box>
        </Box>
        <Box sx={{ mt: 3, textAlign: "right" }}>
          <Button
            type="button"
            onClick={() => navigate("/")}
            disabled={loading}
          >
            {formatMessage(messages.cancel)}
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" type="submit" disabled={loading}>
            {formatMessage(messages.send)}
          </Button>
        </Box>
        {loading && (
          <Box sx={{ mt: 3, width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {resultFiles.length > 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Armazenador</TableCell>
                      <TableCell>Nome do arquivo</TableCell>
                      <TableCell>Nome original</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resultFiles.map((resultFile: IFile) => (
                      <TableRow key={resultFile.id}>
                        <TableCell component="th" scope="row">
                          {resultFile.storage && resultFile.storage.name}
                        </TableCell>
                        <TableCell>{resultFile.name}</TableCell>
                        <TableCell>{resultFile.original_name}</TableCell>
                        <TableCell>
                          <Button
                            disabled={loading}
                            onClick={() => {
                              if (resultFile.id) handleDownload(resultFile.id);
                            }}
                          >
                            <CloudDownloadOutlined />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export { FormFiles };
