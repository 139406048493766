/* eslint-disable import/prefer-default-export */
import axios from 'axios';

interface iZipcode {
  status: number;
  data: {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
  };
}

export const zipcode = async (value: string): Promise<iZipcode> =>
  axios.get(`https://viacep.com.br/ws/${value.replace('-', '')}/json/`);
