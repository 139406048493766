import React from "react";
import { IStorage } from "modules/storages/types";
import { all } from "modules/storages/services";

const useStorages = () => {
  const [storages, setStorages] = React.useState<IStorage[]>([]);

  const getAll = React.useCallback(async () => {
    const { data } = await all();
    setStorages(data);
  }, []);

  React.useEffect(() => {
    getAll();
  }, [getAll]);

  return { storages };
};

export { useStorages };
