import React from "react";
import { ResetPassword } from "modules/auth/components/ResetPassword";
import * as Styled from "./styles";

const Page: React.FC = () => {
  return (
    <Styled.Container>
      <ResetPassword />
    </Styled.Container>
  );
};

export default Page;
