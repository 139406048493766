import { DownloadsLogs } from "modules/files/components/DownloadsLogs";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <DownloadsLogs />;
};

export default withAuthenticationRequired(withSideBar(Page));
