import React from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Button,
  Chip,
  IconButton,
  Collapse,
  LinearProgress,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  SystemUpdateAltOutlined,
  PlayArrow,
  CloseOutlined,
} from "@mui/icons-material";
import { useIntl, defineMessages } from "react-intl";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { IFileDownloads, IFileDownloadsVideo } from "modules/files/types";
import {
  downloadsFiles,
  downloadFile,
  cancelQueue,
} from "modules/files/services";
import { formatDate } from "shared/parsers/date";
import { watchFile } from "modules/files/services";
import { IFile } from "modules/files/types";
import { Player } from "../../components/Player";

const statusDisplay = {
  IN_PROGRESS: "Em progresso",
  COMPLETE: "Finalizado",
  CANCELED: "Cancelado",
};

const messages = defineMessages({
  downloadError: { id: "files.download.error" },
  downloadSuccess: { id: "files.download.success" },
});

const Downloads: React.FC = () => {
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const [lastUpdate, setLastUpdate] = React.useState<Date>(new Date());
  const [loading, setLoading] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<number>(0);
  const [downloads, setDownloads] = React.useState<IFileDownloads[]>([]);
  const [videoOpen, setVideoOpen] = React.useState<boolean>(false);
  const [videoName, setVideoName] = React.useState<string>("");
  const [videoURL, setVideoURL] = React.useState<string>("");
  const [canceling, setCanceling] = React.useState<boolean>(false);

  const handleDownloads = React.useCallback(async () => {
    setLoading(true);
    const { status, data } = await downloadsFiles();
    if (status === 200) {
      setDownloads(data);
    }
    setLastUpdate(new Date());
    setLoading(false);
    setCanceling(false);
  }, []);

  const handleDownloadFile = async (videoId: number) => {
    const { data } = await downloadFile(videoId);
    if (data && data.url) {
      window.open(data.url);
    }
  };

  const handleWatch = async (id: number) => {
    setLoading(true);
    const { status, data: dataWatch } = await watchFile(id);
    if (status === 200) {
      return dataWatch;
    } else {
      openSnackbar({
        message: formatMessage(messages.downloadError),
        severity: "error",
      });
    }
    setLoading(false);
  };

  const handleVideoOpen = async (file: IFile) => {
    if (file.id) {
      const { url } = await handleWatch(file.id);
      setVideoURL(url);
      setVideoName(file.name);
      setVideoOpen(true);
    }
  };

  const handleCancelDownload = async (id: number) => {
    if (window.confirm("Deseja realmente cancelar a conversão?")) {
      setCanceling(true);
      const { status } = await cancelQueue(id);
      if (status === 200) {
        handleDownloads();
      } else {
        setCanceling(false);
      }
    } else {
      setCanceling(false);
    }
    return false;
  };

  const resetVideoOpen = () => {
    setVideoURL("");
    setVideoName("");
    setVideoOpen(false);
    setLoading(false);
  };

  const handleDownloadFiles = async (videos: IFileDownloadsVideo[]) => {
    for (let video of videos) {
      const { data } = await downloadFile(video.file_id);
      if (data && data.url) {
        const link = document.createElement("a");
        link.href = data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        await new Promise((r) => setTimeout(r, 1000));
      }
    }
  };

  React.useEffect(() => {
    handleDownloads();
    setInterval(() => handleDownloads(), 30000);
  }, [handleDownloads]);

  return (
    <Container maxWidth={false}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 5, overflowX: "auto" }}
      >
        <Typography variant="h5" component="h4" fontWeight="bold">
          Meus Downloads
        </Typography>
        <Typography style={{ overflow: "hidden" }}>
          <b>Última atualização:</b>{" "}
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            formatDate(lastUpdate.toISOString(), "dd/MM/yy HH:mm:ss")
          )}
        </Typography>
      </Grid>
      <Paper
        sx={{
          width: "100%",
          borderRadius: 4,
          boxShadow:
            "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        }}
      >
        <TableContainer>
          {downloads.map((dwd: IFileDownloads, index: number) => (
            <Table aria-label="sticky table" key={`idx${index}`}>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "30px" }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(index)}
                    >
                      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Solicitação de arquivos em:{" "}
                    <b>
                      {formatDate(dwd.created_at, "dd/MM/yyyy")} às{" "}
                      {formatDate(dwd.created_at, "HH:mm:ss")}
                    </b>
                    {"  "}
                    <Chip
                      label={statusDisplay[dwd.globalStatus] || "Indefinido"}
                      color={`${
                        dwd.globalStatus === "COMPLETE" ? "success" : "warning"
                      }`}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      onClick={() => handleDownloadFiles(dwd.videos)}
                      title="Baixar Vídeos"
                      size="small"
                      disabled={dwd.globalStatus === "IN_PROGRESS"}
                    >
                      <SystemUpdateAltOutlined />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableCell colSpan={3}>
                  <Collapse in={open === index} timeout="auto" unmountOnExit>
                    <TableContainer>
                      <Table aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Empresa</TableCell>
                            <TableCell>Arquivo</TableCell>
                            <TableCell>Solicitado em</TableCell>
                            <TableCell>Solicitante</TableCell>
                            <TableCell>Finalizado em</TableCell>
                            <TableCell>Situação</TableCell>
                            <TableCell width="20%">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {dwd.videos.length > 0 &&
                            dwd.videos.map(
                              (video: IFileDownloadsVideo, index2: number) => (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={`idx${index2}`}
                                >
                                  <TableCell>
                                    {video.file.company.fantasy_name}
                                  </TableCell>
                                  <TableCell>
                                    {video.file.name.replace(".dav", "")}
                                    {!video.finished_at && (
                                      <LinearProgress color="primary" />
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {formatDate(
                                      video.created_at,
                                      "dd/MM/yyyy HH:mm:ss"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    {`${video.user.name} ${video.user.surname}`}
                                  </TableCell>
                                  <TableCell>
                                    {video.finished_at
                                      ? formatDate(
                                          video.finished_at,
                                          "dd/MM/yyyy HH:mm:ss"
                                        )
                                      : "Processando"}
                                  </TableCell>
                                  <TableCell>
                                    <Chip
                                      label={
                                        statusDisplay[video.status] ||
                                        "Indefinido"
                                      }
                                      color={`${
                                        video.status === "COMPLETE"
                                          ? "success"
                                          : "warning"
                                      }`}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Tooltip title="Assistir">
                                      <Button
                                        disabled={!video.file.mp4_url}
                                        color="primary"
                                        size="small"
                                        aria-label="Assistir"
                                        style={{ minWidth: "24px" }}
                                        onClick={() =>
                                          handleVideoOpen(video.file)
                                        }
                                      >
                                        <PlayArrow />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Baixar">
                                      <Button
                                        disabled={!video.file.mp4_url}
                                        color="primary"
                                        size="small"
                                        aria-label="Baixar"
                                        style={{ minWidth: "24px" }}
                                        onClick={() =>
                                          handleDownloadFile(video.file_id)
                                        }
                                      >
                                        <SystemUpdateAltOutlined />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title="Cancelar">
                                      {canceling ? (
                                        <CircularProgress size={15} />
                                      ) : (
                                        <Button
                                          disabled={video.status === "COMPLETE"}
                                          color="primary"
                                          size="small"
                                          aria-label="Cancelar"
                                          style={{ minWidth: "24px" }}
                                          onClick={() =>
                                            handleCancelDownload(video.id)
                                          }
                                        >
                                          <CloseOutlined />
                                        </Button>
                                      )}
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Collapse>
                </TableCell>
              </TableBody>
            </Table>
          ))}
        </TableContainer>
      </Paper>

      <Player
        open={videoOpen}
        videoName={videoName}
        videoURL={videoURL}
        onClose={() => resetVideoOpen()}
      />
    </Container>
  );
};

export { Downloads };
