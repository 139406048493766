/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useAuthenticationConsumer } from "shared/providers/Authentication";
import { Navigate } from "react-router-dom";
import { ROUTES } from "config/routes";

function withAuthenticationRequired<P>(
  Component: React.ComponentType<P>
): React.FC<P> {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { isAuthenticated } = useAuthenticationConsumer();

    return isAuthenticated ? (
      //@ts-ignore
      <Component {...props} />
    ) : (
      <Navigate to={ROUTES.LOGIN} />
    );
  };
}

export default withAuthenticationRequired;
