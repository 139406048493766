/* eslint-disable import/prefer-default-export */
import { AUTH_TOKEN, AUTH_USER } from "shared/constants/auth";
import { Authenticated } from "shared/types";

export const getAuthenticatedUser = (): Authenticated => {
  const auth = localStorage.getItem(AUTH_TOKEN);
  const user = localStorage.getItem(AUTH_USER);

  if (auth && user) {
    return { auth: JSON.parse(auth), user: JSON.parse(user) };
  }

  return {} as Authenticated;
};

export const clearAuthenticatedUser = (): void => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(AUTH_USER);
};

export const setAuthenticatedUser = ({ auth, user }: Authenticated): void => {
  localStorage.setItem(AUTH_TOKEN, JSON.stringify(auth));
  localStorage.setItem(AUTH_USER, JSON.stringify(user));
};
