import React from "react";
import InputMask from "react-input-mask";

import {
  Grid,
  Typography,
  TextField,
  Box,
  Container,
  Button,
  Tabs,
  Tab,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IUser } from "modules/users/types";
import useCrud from "shared/hooks/use-crud";
import { store, setURI, all } from "shared/services/crud";
import { IUserGroup } from "modules/user-groups/types";
import { useCompanies } from "shared/hooks/useCompanies";
import { me } from "shared/services";

import * as validations from "shared/validations/form";
import { ICompany } from "modules/companies/types";

const messages = defineMessages({
  titleCreate: { id: "users.create" },
  titleEdit: { id: "users.edit" },
  create: { id: "create" },
  save: { id: "save" },
  required: { id: "required" },
  invalid: { id: "invalid" },
  next: { id: "next" },
  success: { id: "crud.register.success" },
  error: { id: "crud.register.error" },
  userGroupField: { id: "users.user.group" },
  companyField: { id: "user.company" },
  passwordNotMatch: { id: "password-not-match" },
  birthday: { id: "birthday" },
  cancel: { id: "cancel" },
});

const TABS = [0, 1];

const Header: React.FC = ({ children }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ marginBottom: 5, overflowX: "auto" }}
    >
      <Typography variant="h5" component="h4" fontWeight="bold">
        {children}
      </Typography>
    </Grid>
  );
};

const initialValues: IUser = {
  name: " ",
  surname: " ",
  document: " ",
  email: " ",
  password: "",
  password_confirmation: "",
  cellphone: " ",
  userGroup: null,
  companies: [],
  blocked: false,
};

const FormUsers: React.FC = () => {
  const uri = "/users";
  setURI(uri);
  const [meData, setMeData] = React.useState({} as IUser);
  const [tab, setTab] = React.useState<number>(TABS[0]);
  const isLastTab = tab === TABS[TABS.length - 1];

  const [userGroupsOptions, setUserGroupsOptions] = React.useState<
    IUserGroup[]
  >([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const { getById, editData } = useCrud<IUser>({
    uri,
    initialValues,
  });

  const schema = yup.object().shape({
    companies: yup.array().required("Campo obrigatório"),
    userGroup: yup.object().required("Campo obrigatório"),
    name: yup
      .string()
      .min(3, "O campo deve conter no mínimo 3 caracteres")
      .required("Campo obrigatório"),
    surname: yup
      .string()
      .min(3, "O campo deve conter no mínimo 3 caracteres")
      .required("Campo obrigatório"),
    document: yup
      .string()
      .required("Campo obrigatório")
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "Digite um CPF válido"),
    email: yup
      .string()
      .email("Digite um email válido")
      .required("Campo obrigatório"),
    cellphone: yup.string().required("Campo obrigatório"),
  });

  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const { companies } = useCompanies();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
  } = useForm<IUser>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const password = React.useRef<unknown>({});
  password.current = watch("password", "");

  React.useEffect(() => {
    if (!id) return;
    getById(Number(id));
  }, [getById, id]);

  React.useEffect(() => {
    editData.document = editData.document.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
    reset(editData);
  }, [editData, reset]);

  const onSubmit = async ({
    userGroup,
    companies,
    document,
    cellphone,
    ...values
  }: IUser) => {
    if (!isLastTab) return setTab(TABS[tab + 1]);

    const companyIds: number[] = [];

    companies.forEach((item: ICompany) => companyIds.push(item.id));

    const data = {
      ...values,
      user_group_id: userGroup?.id,
      company_ids: companyIds,
      document: document ? document.replace(/[^\w\s]/gi, "") : "",
      cellphone: cellphone
        ? cellphone.replace(/[^\w\s]/gi, "").replace(/\s/g, "")
        : null,
    };

    if (!values?.password) {
      delete data.password;
      delete data.password_confirmation;
    }

    const dataReturn = await store(data);

    if (dataReturn && dataReturn.id) {
      openSnackbar({
        message: formatMessage(messages.success),
        severity: "success",
      });
      navigate(ROUTES.ADMIN.USERS);
    } else {
      openSnackbar({
        message: "Já existe um usuário configurado com esses dados.",
        severity: "error",
      });
      return false;
    }
  };

  const handleUserGroups = React.useCallback(async () => {
    try {
      const { data, status } = await all("/user-groups");
      if (status === 200) {
        setUserGroupsOptions(data);
      }
    } catch (e) {
      openSnackbar({
        message: formatMessage(messages.error),
        severity: "error",
      });
    }
  }, [formatMessage, openSnackbar]);

  React.useEffect(() => {
    handleUserGroups();
  }, [handleUserGroups]);

  const loadMe = React.useCallback(async () => {
    const { status, data } = await me();
    if (status === 200) setMeData(data);
  }, []);

  React.useEffect(() => {
    loadMe();
  }, [loadMe]);

  return (
    <Container maxWidth="lg">
      <Header>
        {id
          ? formatMessage(messages.titleEdit)
          : formatMessage(messages.titleCreate)}
      </Header>

      <Tabs
        value={tab}
        onChange={(_, newValue: number) => setTab(newValue)}
        aria-label="basic tabs example"
        sx={{
          mb: 5,
        }}
      >
        <Tab label="Pessoal" id="tab-0p" />
        <Tab label="Segurança" id="tab-2" />
      </Tabs>
      <Box
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        boxShadow={1}
        style={{ display: "grid", gridAutoFlow: "row" }}
        sx={{ padding: 3, borderRadius: 4 }}
        autoComplete="off"
        noValidate
      >
        {tab === 0 && (
          <Box
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
            sx={{ gap: 3 }}
          >
            {meData &&
              meData.userGroup &&
              meData.userGroup.uid &&
              ["ADMIN", "MANAGER"].includes(meData.userGroup.uid) && (
                <>
                  <div>
                    <Controller
                      control={control}
                      name="companies"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { invalid, error },
                      }) => (
                        <Autocomplete
                          autoHighlight
                          multiple
                          options={companies}
                          getOptionLabel={(label) => label.fantasy_name}
                          value={value}
                          onChange={(_, selected?) => {
                            onChange(selected);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              error={invalid}
                              helperText={
                                validations.isRequiredError(error?.type) &&
                                formatMessage(messages.required)
                              }
                              label={formatMessage(messages.companyField)}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      control={control}
                      name="userGroup"
                      rules={{ required: true }}
                      render={({
                        field: { onChange, value },
                        fieldState: { invalid, error },
                      }) => (
                        <Autocomplete
                          autoHighlight
                          autoSelect
                          options={userGroupsOptions}
                          getOptionLabel={(label) => label.name}
                          value={{
                            name: value?.name ?? "",
                            id: value?.id ?? 0,
                          }}
                          defaultValue={userGroupsOptions.find(
                            (group) => group.id === editData?.id
                          )}
                          onChange={(_, selected?) => {
                            onChange(selected);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              error={invalid}
                              helperText={
                                validations.isRequiredError(error?.type) &&
                                formatMessage(messages.required)
                              }
                              label={formatMessage(messages.userGroupField)}
                              defaultValue={editData?.name}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            <div>
              <TextField
                label="Nome"
                fullWidth
                {...register("name", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
              />
            </div>
            <div>
              <TextField
                label="Sobrenome"
                fullWidth
                {...register("surname", {
                  required: formatMessage(messages.required),
                })}
                error={Boolean(errors.name)}
                helperText={errors.surname && errors.surname.message}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="document"
                render={({ field: { ...field } }) => (
                  <InputMask mask="999.999.999-99" {...field}>
                    {/*
                    // @ts-ignore:next-line */}
                    {(inputProps) => (
                      <TextField
                        label="CPF"
                        fullWidth
                        error={Boolean(errors.document)}
                        helperText={errors.document && errors.document.message}
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <div>
              <TextField
                label="Email"
                fullWidth
                {...register("email")}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="cellphone"
                render={({
                  field: { ...field },
                  fieldState: { invalid, error },
                }) => (
                  <InputMask mask="(99) 9 9999-9999" {...field}>
                    {/*
                    // @ts-ignore:next-line */}
                    {(inputProps) => (
                      <TextField
                        label="Celular"
                        fullWidth
                        error={invalid}
                        helperText={
                          validations.isRequiredError(error?.type) &&
                          formatMessage(messages.required)
                        }
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                )}
              />
            </div>
            <div>
              <Controller
                control={control}
                name="blocked"
                render={({ field: { onChange, value } }) => (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch defaultChecked={value} checked={value} />
                      }
                      label="Acesso Bloqueado"
                      onChange={onChange}
                    />
                    <p style={{ fontSize: "11px", color: "orange" }}>
                      Atenção! Ao bloquear o acesso, o usuário será deslogado.
                    </p>
                  </FormGroup>
                )}
              />
            </div>
          </Box>
        )}
        {tab === 1 && (
          <Box
            style={{ display: "grid", gridTemplateColumns: "1fr" }}
            sx={{ gap: 3 }}
          >
            <div>
              <TextField
                label="Senha"
                type="password"
                fullWidth
                {...register("password", {
                  required: id ? false : formatMessage(messages.required),
                  minLength: {
                    value: 8,
                    message: formatMessage(messages.invalid),
                  },
                })}
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
              />
            </div>
            <div>
              <TextField
                label="Confirmar a Senha"
                fullWidth
                type="password"
                {...register("password_confirmation", {
                  required: id ? false : formatMessage(messages.required),
                  validate: (value) =>
                    value === password.current ||
                    formatMessage(messages.passwordNotMatch),
                })}
                error={Boolean(errors.password_confirmation)}
                helperText={
                  errors.password_confirmation &&
                  errors.password_confirmation.message
                }
              />
            </div>
            {id && (
              <i>
                * Só preencha esses campos caso queira alterar a senha do
                usuário.
              </i>
            )}
          </Box>
        )}

        <Box sx={{ mt: 3, textAlign: "right" }}>
          <Button type="button" onClick={() => navigate(ROUTES.ADMIN.USERS)}>
            {formatMessage(messages.cancel)}
          </Button>
          &nbsp;&nbsp;
          <Button variant="contained" type="submit">
            {!isLastTab
              ? formatMessage(messages.next)
              : formatMessage(id ? messages.save : messages.create)}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export { FormUsers };
