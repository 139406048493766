import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from "@mui/material";

import { useIntl, defineMessages } from "react-intl";

interface IConfirmDialog {
  title: string;
  description: string;
  open: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
}

const messages = defineMessages({
  confirm: { id: "confirm" },
  cancel: { id: "cancel" },
});

const ConfirmDialog: React.FC<IConfirmDialog> = ({
  title,
  description,
  open,
  handleCancel,
  handleConfirm,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Dialog open={open} onClose={handleCancel} PaperComponent={Paper}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          {formatMessage(messages.cancel)}
        </Button>
        <Button onClick={handleConfirm}>
          {formatMessage(messages.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog };
