import React from "react";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { IScanLogReport } from "modules/scan-logs/types";
import { report } from "modules/scan-logs/services";
import { formatDate } from "shared/parsers/date";

const ScanLogsDashboard: React.FC = () => {
  const [data, setData] = React.useState<IScanLogReport[]>([]);

  const handleReport = React.useCallback(async () => {
    const { status, data: dataReport } = await report();
    if (status === 200) {
      setData(dataReport);
    }
  }, []);

  React.useEffect(() => {
    handleReport();
  }, [handleReport]);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 5, overflowX: "auto" }}
      >
        <Typography variant="h5" component="h4" fontWeight="bold">
          Scan Logs
        </Typography>
      </Grid>
      <Grid container>
        <Paper
          sx={{
            width: "100%",
            borderRadius: 4,
            boxShadow:
              "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
          }}
        >
          <TableContainer>
            <Table aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Última Atualização</TableCell>
                  <TableCell align="center">Quantidade de arquivos</TableCell>
                  <TableCell align="center">Arquivos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((log: IScanLogReport) => (
                  <TableRow key={Math.random()}>
                    <TableCell align="center">
                      {formatDate(log.created_at)}
                    </TableCell>
                    <TableCell align="center">{log.num_files}</TableCell>
                    <TableCell align="center">{log.files}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Container>
  );
};

export { ScanLogsDashboard };
