import React from "react";
import { Box } from "@mui/material";
import logo from "assets/images/inspecionarLogo.png";

export interface IFormBox {
  children: any;
  title: string;
  subtitle: string;
}

const FormBox: React.FC<IFormBox> = ({ children, title, subtitle }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          paddingTop: 6,
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 10,
          width: 450,
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            <img src={logo} width={295} alt="inspecionar-logo" />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              borderRadius: "10px",
              backgroundColor: "#FFF",
              padding: "10px 30px 30px",
            }}
          >
            {children}
          </Box>
        </>
      </Box>
    </Box>
  );
};

export { FormBox };
