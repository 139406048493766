import React from "react";
import { Container } from "@mui/material";
import { ROUTES } from "config/routes";
import { List } from "shared/components/List";
import { useIntl, defineMessages } from "react-intl";
import { columns } from "./columns";
import { UserFilters, IFilters } from "../UserFilters";

const messages = defineMessages({
  title: { id: "users" },
});

const ListUsers: React.FC = () => {
  const { formatMessage } = useIntl();
  const [filters, setFilters] = React.useState<IFilters>({} as IFilters);
  return (
    <Container maxWidth={false}>
      <UserFilters onChange={(values) => setFilters(values)} />
      <List
        title={formatMessage(messages.title)}
        columns={columns}
        uri={ROUTES.ADMIN.USERS}
        filters={filters}
      />
    </Container>
  );
};

export { ListUsers };
