/* eslint-disable import/prefer-default-export */
import { format, differenceInMinutes } from "date-fns";

const DEFAULT_DATE_FORMAT = "dd/MM/yy hh:mm:ss";

export function formatDate(
  date: number | Date | string,
  dateFormat?: string
): string {
  if (typeof date === "string")
    return format(new Date(date), dateFormat || DEFAULT_DATE_FORMAT);

  return format(date, dateFormat || DEFAULT_DATE_FORMAT);
}

export function getDifferenceInMinutes(start: Date, end: Date): number {
  return differenceInMinutes(start, end);
}
