import { AccessLogs } from "modules/users/components/AccessLogs";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <AccessLogs />;
};

export default withAuthenticationRequired(withSideBar(Page));
