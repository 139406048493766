import axiosInstance from "shared/services";
import { getPropData } from "shared/parsers";

let uri = "";

export const setURI = (param: string): void => {
  uri = param;
};

export const list = async (page?: number, filters?: any): Promise<any> => {
  const queryParams = `?${new URLSearchParams(filters).toString()}`;
  return axiosInstance
    .get(`${uri}${queryParams}`, {
      params: {
        page,
      },
    })
    .then();
};

export const all = async (url?: string): Promise<any> =>
  axiosInstance.get(`${url || uri}/all`).then();

export const first = async (id: number): Promise<any> =>
  axiosInstance.get(`${uri}/${id}`).then(getPropData);

export const remove = async (id: number): Promise<any> =>
  axiosInstance.delete(`${uri}/${id}`);

export const store = async <T extends { id?: number }>(
  data: T
): Promise<any> => {
  if (data.id) {
    return axiosInstance.put<T>(`${uri}/${data.id}`, data).then(getPropData);
  }
  return axiosInstance.post<T>(`${uri}`, data).then(getPropData);
};
