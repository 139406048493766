import styled from "@emotion/styled";

export const Container = styled.section`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background: #1390cb;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Container;
