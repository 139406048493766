import { formatDate } from "shared/parsers/date";

export interface Column {
  id:
    | "id"
    | "name"
    | "uid"
    | "avaliable"
    | "created_at"
    | "updated_at"
    | "options";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: string | number) => string;
}

const columns: Column[] = [
  {
    id: "id",
    label: "ID",
    minWidth: 80,
  },
  {
    id: "name",
    label: "Nome",
    minWidth: 170,
  },
  {
    id: "uid",
    label: "UID",
    minWidth: 80,
    align: "right",
  },
  {
    id: "avaliable",
    label: "Ativo",
    minWidth: 30,
    align: "right",
  },
  {
    id: "created_at",
    label: "Criado em",
    minWidth: 170,
    align: "right",
    format: formatDate,
  },
  {
    id: "updated_at",
    label: "Atualizado em",
    minWidth: 170,
    align: "right",
    format: formatDate,
  },
  {
    id: "options",
    label: "Ações",
    minWidth: 170,
    align: "right",
  },
];

export { columns };
