import React from "react";
import { Box } from "@mui/material";
import "rsuite/dist/rsuite.min.css";
import { InputGroup, Input } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";

interface IProps {
  onChange: (values: IFilters) => void;
}

export interface IFilters {
  query: string;
}

const CompanyFilters = ({ onChange }: IProps) => {
  const [filters, setFilters] = React.useState<IFilters>({} as IFilters);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        paddingBottom: "15px",
        marginBottom: "15px",
        borderBottom: "1px solid #CCC",
      }}
    >
      <form
        style={{ display: "flex", width: "100%" }}
        onSubmit={(e) => e.preventDefault()}
      >
        <div style={{ width: "50%", marginRight: 10 }}>
          <label>
            <b>Buscar por nome ou CNPJ</b>
          </label>
          <InputGroup>
            <Input
              placeholder=""
              value={filters.query}
              style={{ width: "100%" }}
              onChange={(query: string) => {
                setFilters({ ...filters, query });
                onChange({ ...filters, query });
              }}
            />
            <InputGroup.Addon>
              <SearchIcon />
            </InputGroup.Addon>
          </InputGroup>
        </div>
      </form>
    </Box>
  );
};

export { CompanyFilters };
